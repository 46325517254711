<template>
  <div>
    <Collapsible
      class="example-collapsible"
      :is-open="isOpen"
      :on-collapse="collapse"
    >
      <div
        slot="trigger"
        class="d-flex justify-content-between"
      >
        <div class="text-nowrap">
          <h6 class="text-danger">
            <text-highlight
              :queries="searchTermsWithOutpudDate"
              highlight-style="background-color: #ebd834;"
            >
              {{ invoices[0].folio }}

            </text-highlight>
          </h6>
          <h6>
            <text-highlight
              :queries="searchTermsWithOutpudDate"
              highlight-style="background-color: #ebd834;"
            >
              {{ invoices[0].updated_at | moment("DD/MM/YYYY") }}
            </text-highlight>
          </h6>
        </div>

        <b-button
          v-if="invoices.length > 1"
          :variant="visible ? 'flat-primary' : 'flat-secondary'"
          class="btn-icon rounded-circle ml-2 align-self-center"
        >
          <feather-icon :icon="iconToggle" />
        </b-button>
      </div>

      <div
        v-for="(invoice, index) in invoicesExceptFirst"
        :key="index"
        class="my-1 text-nowrap"
      >
        <h6 class="text-danger">
          <text-highlight
            :queries="searchTermsWithOutpudDate"
            highlight-style="background-color: #ebd834;"
          >
            {{ invoice.folio }}
          </text-highlight>
        </h6>
        <h6>
          <text-highlight
            :queries="searchTermsWithOutpudDate"
            highlight-style="background-color: #ebd834;"
          >
            {{ invoice.updated_at | moment("DD/MM/YYYY") }}
          </text-highlight>
        </h6>
      </div>
    </Collapsible>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BButton } from 'bootstrap-vue'

import TextHighlight from 'vue-text-highlight'
import Collapsible from 'vue-collapsible-component'
import 'vue-collapsible-component/lib/vue-collapsible.css'

export default {
  components: {
    BButton,
    Collapsible,
    TextHighlight,
  },
  props: {
    invoices: {
      type: Array,
      required: true,
    },
    /**
     * Indica si se pueden aplicar los filtros globales
     */
    canApplyGlobalFilters: {
      type: Boolean,
      default: true,
    },
    localSearchTerm: {
      type: String,
      default: '',
    },
    localOutputDate: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpen: false,
      visible: false,
      iconToggle: 'ChevronDownIcon',
    }
  },
  computed: {
    ...mapGetters({
      getSearchTerms: 'filters/getSearchTerms',
      getFilteredOutputDate: 'filters/getFilteredOutputDate',
    }),
    /**
     * Arreglo de los términos de búsqueda (filtro en store)
     */
    searchTerms: {
      get() {
        /**
         * Verifica que se puedan aplicar filtros globales, de lo contrario se
         * toman los términos de búsqueda locales
         */
        if (this.canApplyGlobalFilters) {
          return this.getSearchTerms
        }

        return this.localSearchTerm.split(' ')
      },
    },
    /**
     * Fecha de salida global (filtro en store)
     */
    filteredOutputDate: {
      get() {
        /**
         * Verifica que se puedan aplicar filtros globales, de lo contrario se
         * toma la fecha de salida local
         */
        if (this.canApplyGlobalFilters) {
          return this.getFilteredOutputDate
        }

        return this.localOutputDate
      },
    },
    /**
     * Registros de folios y fechas de factura exceptuando el primero
     */
    invoicesExceptFirst() {
      return this.invoices.slice(1)
    },
    /**
     * Arreglo de los términos de búsqueda (filtro en store) con la fecha de salida
     */
    searchTermsWithOutpudDate() {
      return [...this.searchTerms, this.$moment(this.filteredOutputDate).format('DD/MM/YYYY')]
    },
    /**
     * Determina si los datos de la factura coinciden con los términos de búsqueda
     */
    isDataMatchingWithSearcTerms() {
      return this.searchTerms.some(
        searchTerm => {
          if (searchTerm) {
            return this.invoices.some(
              invoice => invoice.folio.includes(searchTerm)
              || this.$moment(invoice.updated_at).format('DD/MM/YYYY').includes(searchTerm),
            )
          }
          return false
        },
      )
    },
    /**
     * Determina si los datos de la factura coinciden con la fecha de salida
     */
    isDataMatchingWithFilteredOutputDate() {
      if (this.filteredOutputDate) {
        const formatedDate = this.$moment(this.filteredOutputDate).format('DD/MM/YYYY')
        return this.invoices.some(
          invoice => this.$moment(invoice.updated_at).format('DD/MM/YYYY').includes(formatedDate),
        )
      }
      return false
    },
  },

  /**
   * Hook que se ejecuta cuando se monta el componente
   * @sumary Verifica si los datos de la factura coinciden con los términos de búsqueda
   * o con la fecha de salida. Si es así, muestra el componente abierto
   */
  mounted() {
    this.isOpen = this.isDataMatchingWithSearcTerms || this.isDataMatchingWithFilteredOutputDate
  },

  methods: {
    collapse() {
      this.$root.$emit('bv::toggle::collapse', this.collapseItemId)
      this.visible = !this.visible
      this.setToggleIcon()
    },
    setToggleIcon() {
      this.iconToggle = this.visible ? 'ChevronUpIcon' : 'ChevronDownIcon'
    },
  },
}
</script>
