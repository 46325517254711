var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('QuotesListFilters',{attrs:{"user-detail":_vm.userDetail,"type-user":_vm.typeUser,"title":"Cotizaciones","new-item-button-label":"Nueva cotización","searcher-placeholder":"Buscar cotización","filter-route":_vm.filterRoute,"can-apply-global-filters":_vm.canApplyGlobalFilters},on:{"on:filter":_vm.handleFilter,"on:click":function($event){return _vm.$router.push({ name: 'new-quote' })}}}),(_vm.isLoadingQuotes)?[_c('b-skeleton',{staticClass:"mt-1",attrs:{"animation":"wave","width":"100%","height":"430px"}})]:[_c('b-card',{staticClass:"mt-1 border border-dark shadow-none",attrs:{"no-body":""}},[_c('b-card-text',[(_vm.availableQuotes)?_c('b-table',{staticClass:"my-0",attrs:{"items":_vm.quotes,"fields":_vm.columns,"responsive":""},scopedSlots:_vm._u([{key:"cell(No)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.indexFrom + data.index)+" ")]}},{key:"cell(ID)",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'quote-details', params: { id: item.IdQuote } }}},[_vm._v(" "+_vm._s(item.ID)+" ")])]}},{key:"cell(created_at)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("moment")(item.created_at,"DD/MM/YYYY hh:mm a")))])]}},{key:"cell(NameUser)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.NameUser))])]}},{key:"cell(client)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(((item.NameClient) + " " + (item.LastNameClient))))])]}},{key:"cell(Qty)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("placeholder")(item.Qty,'Sin especificar')))])]}},{key:"cell(Invoice)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("placeholder")(item.Invoice,'Sin especificar')))])]}},{key:"cell(SubtotalUsd)",fn:function(ref){
var item = ref.item;
return [( item.SubtotalUsd)?_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.SubtotalUsd))+" ")]):_c('span',{staticClass:"text-nowrap"},[_vm._v("Sin especificar")])]}},{key:"cell(ExchangeRate)",fn:function(ref){
var item = ref.item;
return [( item.ExchangeRate)?_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.ExchangeRate))+" ")]):_c('span',{staticClass:"text-nowrap"},[_vm._v("Sin especificar")])]}},{key:"cell(TotalMxn)",fn:function(ref){
var item = ref.item;
return [( item.TotalMxn)?_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.TotalMxn))+" ")]):_c('span',{staticClass:"text-nowrap"},[_vm._v("Sin especificar")])]}},{key:"cell(StatusText)",fn:function(ref){
var item = ref.item;
return [_c('h6',[_c('b-badge',{staticClass:"ava-label",attrs:{"variant":_vm.getStatusStyle(item.StatusText)}},[_vm._v(" "+_vm._s(item.StatusText)+" ")])],1)]}}],null,false,3359166909)}):_vm._e(),(!_vm.availableQuotes && !_vm.isLoadingQuotes)?_c('b-alert',{staticClass:"my-0",attrs:{"variant":"warning","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v("No se encontraron cotizaciones.")])])]):_vm._e()],1)],1)],(_vm.availableQuotes)?_c('BasicPaginator',{ref:"basic-paginator",staticClass:"mt-2",attrs:{"total-rows":_vm.totalQuotes,"callback":_vm.handleChangePagination,"can-apply-global-pagination":_vm.canApplyGlobalPagination}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }