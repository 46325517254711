<template>
  <b-card
    no-body
    class="card-statistics border border-dark shadow-none"
  >
    <b-card-header>
      <b-card-title>{{ title }}</b-card-title>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="item, index in items"
          :key="item.icon + index"
          class="mb-2 mb-md-0"
          :class="grid == 'ordenes' ? 'col-4 ordenes' : grid"
        >
          <b-media
            class="mb-3"
            no-body
          >
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="40"
                :variant="item.color"
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText, BCardHeader, BCardTitle, BCardBody, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      required: true,
    },
    grid: {
      type: String,
      default: 'cotizaciones',
    },
  },
}
</script>
<style lang="scss" scoped>

.cotizaciones{
  width: calc(100% / 4);
  max-width: calc(100% / 4);
  flex-basis: auto;
  @media screen and (max-width: 767px) {
    width: 150px;
    max-width: inherit;
  }
}
.ordenes{
  width: calc(100% / 4);
  flex-basis: auto;
  @media screen and (max-width: 991px) {
    width: calc(100% / 4);
    max-width: calc(100% / 4);
  }
  @media screen and (max-width: 767px) {
    width: 150px;
    max-width: inherit;
  }
}
</style>
