<template>
  <div>

    <!-- #region::title -->
    <b-form-row>
      <b-col cols="12">
        <h3>{{ title }}</h3>
      </b-col>
    </b-form-row>
    <!-- #endregion::title -->

    <b-form-row>
      <b-col :md="canShowColumnsListFilter ? 10 : 12">

        <!-- #region::Order list filters -->
        <b-form-row>

          <!-- #region:::Searcher -->
          <b-col
            md="2"
            class="my-50"
          >
            <BasicSearcher
              ref="basic-searcher"
              :callback="handleSearch"
              :placeholder="searcherPlaceholder"
            />
          </b-col>
          <!-- #endregion::Searcher -->

          <!-- #region::Order status select -->
          <b-col
            v-if="canShowFilter(STATUS_ORDER_FILTER_VALUE)"
            md="2"
            class="my-50"
          >
            <v-select
              v-model="selectedOrderStatus"
              label="status"
              class="style-chooser"
              placeholder="Estatus de orden"
              :options="getOrdersStatus"
            />
          </b-col>
          <!-- #endregion::Order status select -->

          <!-- #region::Payment status select -->
          <b-col
            v-if="canShowPaymentStatusFilter && canShowFilter(STATUS_PAYMENT_FILTER_VALUE)"
            md="2"
            class="my-50"
          >
            <v-select
              v-model="selectedPaymentStatus"
              label="status"
              class="style-chooser"
              placeholder="Estatus de pago"
              :options="getPaymentsStatus"
            />
          </b-col>
          <!-- #endregion::Payment status select -->

          <!-- #region::Required invoice select -->
          <b-col
            v-if="canShowRequiredInvoiceFilter && canShowFilter(REQUIRED_INVOICE_FILTER_VALUE)"
            md="2"
            class="my-50"
          >
            <v-select
              v-model="selectedInvoicedOption"
              label="text"
              class="style-chooser"
              placeholder="Factura"
              :options="getInvoicedOptions"
            />
          </b-col>
          <!-- #endregion::Required invoice select -->

          <!-- #region::ID sorter filter -->
          <b-col
            v-if="canShowFilter(ID_FILTER_VALUE)"
            md="2"
            class="my-50"
          >
            <v-select
              v-model="selectedIdOption"
              label="text"
              class="style-chooser"
              placeholder="ID"
              :options="getIdOptions"
            >
              <template #option="{ text, icon }">
                <feather-icon
                  :icon="icon"
                  size="16"
                  class="align-middle mr-50"
                />
                <span> {{ text }}</span>
              </template>
            </v-select>
          </b-col>
          <!-- #endregion::ID sorter filter -->

          <!-- #region::Customer filter -->
          <b-col
            v-if="canShowCustomersFilter && canShowFilter(CUSTOMER_FILTER_VALUE)"
            md="2"
            class="my-50"
          >
            <vue-autosuggest
              v-model="filteredCustomer.name"
              :suggestions="customersOptions"
              :limit="5"
              :input-props="{
                id:'autosuggest__input1',
                class:'form-control',
                placeholder:'Buscar por cliente'
              }"
              @input="onCustomerNameChange"
              @selected="onSelectedCustomerName"
            >
              <template slot-scope="{suggestion}">
                <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
              </template>
            </vue-autosuggest>
          </b-col>
          <!-- #endregion::Customer filter -->

          <!-- #region::Creators filter -->
          <b-col
            v-if="canShowCreatorsFilter && canShowFilter(CREATOR_FILTER_VALUE)"
            md="2"
            class="my-50"
          >
            <vue-autosuggest
              v-model="filteredCreator.name"
              :suggestions="creatorsOptions"
              :limit="5"
              :input-props="{
                id:'autosuggest__input2',
                class:'form-control',
                placeholder:'Buscar por creador'
              }"
              @input="onCreatorNameChange"
              @selected="onSelectedCreatorName"
            >
              <template slot-scope="{suggestion}">
                <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
              </template>
            </vue-autosuggest>
          </b-col>
          <!-- #endregion::Creators filter -->

          <!-- #region::Required location select -->
          <b-col
            v-if="canShowLocationFilter && canShowFilter(LOCATION_FILTER_VALUE)"
            md="2"
            class="my-50"
          >
            <v-select
              v-model="selectedLocation"
              label="text"
              class="style-chooser"
              placeholder="Ubicación"
              :options="getLocations"
            />
          </b-col>
          <!-- #endregion::Required location select -->

          <!-- #region::Required date filter -->
          <b-col
            v-if="canShowFilter(DATE_FILTER_VALUE)"
            md="2"
            class="my-50"
          >
            <b-form-datepicker
              v-model="selectedDate"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              placeholder="Fecha de solicitud"
              local="es"
              reset-button
            />
          </b-col>
          <!-- #endregion::Required date filter -->

          <!-- #region::Output date filter -->
          <b-col
            v-if="canShowFilter(OUTPUT_DATE_FILTER_VALUE)"
            md="2"
            class="my-50"
          >
            <b-form-datepicker
              v-model="selectedOutputDate"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              placeholder="Fecha salida"
              local="es"
              reset-button
            />
          </b-col>
          <!-- #endregion::Output date filter -->

          <!-- #region::Approved by store date filter -->
          <b-col
            v-if="canShowFilter(APPROVED_BY_STORE_DATE_FILTER_VALUE)"
            md="2"
            class="my-50"
          >
            <b-form-datepicker
              v-model="selectedApprovedByStoreDate"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              placeholder="Fecha aprob. bodega"
              local="es"
              reset-button
            />
          </b-col>
          <!-- #endregion::Approved by store date filter -->

          <!-- #region::Received date filter -->
          <b-col
            v-if="canShowFilter(COMPLETED_MONTH_FILTER_VALUE)"
            md="2"
            class="my-50"
          >
            <vue-monthly-picker
              v-model="selectedReceivedMonth"
              place-holder="Mes completada"
              date-format="MMMM yyyy"
              :month-labels="monthLabels"
              :input-class="['month-picker-style']"
              :min="minDate"
            />
          </b-col>
          <!-- #endregion::Received date filter -->

          <!-- #region::Order status select -->
          <b-col
            v-if="canShowPaymentStatusFilter && canShowFilter(COMMISSION_STATUS_FILTER_VALUE)"
            md="2"
            class="my-50"
          >
            <v-select
              v-model="selectedCommissionStatus"
              label="text"
              class="style-chooser"
              placeholder="Estatus de comisión"
              :options="getCommissionStatus"
            />
          </b-col>
          <!-- #endregion::Order status select -->

        </b-form-row>
        <!-- #endregion::Order list filters -->

      </b-col>

      <b-col
        v-if="canShowColumnsListFilter"
        md="2"
      >

        <!-- #region::Order list options -->
        <b-form-row>

          <!-- #region::Columns filter -->
          <b-col
            cols="12"
            md="auto"
            class="ml-auto my-50"
          >
            <b-dropdown
              variant="outline-secondary"
              class="style-chooser"
              dropright
            >

              <template slot="button-content">
                <feather-icon
                  icon="ColumnsIcon"
                  class="mr-50"
                />
              </template>

              <b-dropdown-form>

                <!-- #region::Filter columns -->
                <BasicSearcher
                  ref="filter-searcher"
                  :callback="handleSearchColumns"
                  placeholder="Buscar"
                />
                <!-- #endregion::Filter columns -->

                <b-form-checkbox
                  v-for="(column, index) in filteredColumsBySearchTerm"
                  :key="index"
                  class="mt-50"
                  :checked="column.active"
                  @change="onColumnOptionChange(column)"
                >
                  {{ column.text }}
                </b-form-checkbox>
              </b-dropdown-form>
            </b-dropdown>
          </b-col>
          <!-- #endregion::Columns filter -->

          <!-- #region::Sending email button -->
          <b-col
            cols="12"
            md="auto"
            class="my-50"
          >
            <b-button
              variant="flat-success"
              class="csv-btn float-right"
              :disabled="isSendingEmail"
              @click="onSendingEmail"
            >
              <b-spinner
                v-if="isSendingEmail"
                small
              />
              <feather-icon
                v-if="!isSendingEmail"
                icon="MailIcon"
              />
            </b-button>
          </b-col>
          <!-- #endregion::Sending email button -->

        </b-form-row>
        <!-- #endregion::Order list options -->

      </b-col>
    </b-form-row>

  </div>
</template>

<script>
// #region Imports
import { mapGetters, mapActions } from 'vuex'
import {
  BCol, BFormRow, BFormDatepicker, BDropdown, BDropdownForm, BFormCheckbox, BButton, BSpinner,
} from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'

import vSelect from 'vue-select'
import monthLabels from '@/helpers/MonthLabels'
import VueMonthlyPicker from 'vue-monthly-picker'
import BasicSearcher from '@/components/forms/BasicSearcher.vue'

import getError from '@/helpers/ErrorsHandler'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// #endregion

const ID_FILTER_VALUE = 'ID'
const CUSTOMER_FILTER_VALUE = 'client'
const LOCATION_FILTER_VALUE = 'location'
const CREATOR_FILTER_VALUE = 'created_by'
const DATE_FILTER_VALUE = 'fecha_aprobada'
const REQUIRED_INVOICE_FILTER_VALUE = 'invoice'
const STATUS_ORDER_FILTER_VALUE = 'order_status'
const STATUS_PAYMENT_FILTER_VALUE = 'payment_status'
const COMPLETED_MONTH_FILTER_VALUE = 'completed_date'
const OUTPUT_DATE_FILTER_VALUE = 'invoice_and_exit_date'
const COMMISSION_STATUS_FILTER_VALUE = 'commission'
const APPROVED_BY_STORE_DATE_FILTER_VALUE = 'confirmed_delivery_date'

export default {
  components: {
    BCol,
    vSelect,
    BButton,
    BFormRow,
    BSpinner,
    BDropdown,
    BDropdownForm,
    BFormCheckbox,
    BasicSearcher,
    VueAutosuggest,
    BFormDatepicker,
    VueMonthlyPicker,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    searcherPlaceholder: {
      type: String,
      required: true,
    },
    userDetail: {
      type: Boolean,
      default: false,
    },
    typeUser: {
      type: String,
      default: '',
    },
    filterRoute: {
      type: String,
      default: '',
    },
    /**
     * Determina si se cargan los filtros globales del estado
     */
    canApplyGlobalFilters: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedDate: '',
      selectedCreator: '',
      selectedLocation: '',
      selectedIdOption: '',
      selectedCustomer: '',
      selectedOutputDate: '',
      selectedOrderStatus: '',
      selectedPaymentStatus: '',
      selectedReceivedMonth: '',
      selectedInvoicedOption: '',
      selectedCommissionStatus: '',
      selectedApprovedByStoreDate: '',

      filteredOptions: [],
      userData: JSON.parse(localStorage.getItem('userData')),

      customersOptions: [],
      creatorsOptions: [],

      monthLabels,
      /**
       * Set 2021 as min selectable date
       */
      minDate: this.$moment('2021-01-01'),
      /**
       * Término de búsqueda de columnas
       */
      columnsSearchTerm: '',
      isSendingEmail: false,

      /**
       * Nombre de los campos de la tabla
       */
      ID_FILTER_VALUE,
      DATE_FILTER_VALUE,
      CREATOR_FILTER_VALUE,
      CUSTOMER_FILTER_VALUE,
      LOCATION_FILTER_VALUE,
      OUTPUT_DATE_FILTER_VALUE,
      STATUS_ORDER_FILTER_VALUE,
      STATUS_PAYMENT_FILTER_VALUE,
      COMPLETED_MONTH_FILTER_VALUE,
      REQUIRED_INVOICE_FILTER_VALUE,
      COMMISSION_STATUS_FILTER_VALUE,
      APPROVED_BY_STORE_DATE_FILTER_VALUE,
    }
  },
  computed: {
    ...mapGetters({
      getIdOptions: 'quotes/getIdOptions',
      getLocations: 'orders/getLocations',
      getOrdersStatus: 'orders/getOrdersStatus',
      getPaymentsStatus: 'orders/getPaymentsStatus',
      getClientsSuggests: 'orders/getClientsSuggests',
      getInvoicedOptions: 'orders/getInvoicedOptions',
      getCreatorsSuggests: 'orders/getCreatorsSuggests',
      getCommissionStatus: 'orders/getCommissionStatus',
      /**
       * Filtros globales
       */
      getFilteredSortBy: 'filters/getFilteredSortBy',
      getFilteredStatus: 'filters/getFilteredStatus',
      getListsParams: 'filters/getFilteredListParams',
      getFilteredCreator: 'filters/getFilteredCreator',
      getFilteredLocation: 'filters/getFilteredLocation',
      getFilteredCustomer: 'filters/getFilteredCustomer',
      getFilteredOutputDate: 'filters/getFilteredOutputDate',
      getFilteredSearchTerm: 'filters/getFilteredSearchTerm',
      getFilteredRequiredDate: 'filters/getFilteredRequiredDate',
      getFilteredPaymentStatus: 'filters/getFilteredPaymentStatus',
      getFilteredInvoiceOption: 'filters/getFilteredInvoiceOption',
      getFilteredReceivedMonth: 'filters/getFilteredReceivedMonth',
      getFilteredCommissionStatus: 'filters/getFilteredCommissionStatus',
      getFilteredApprovedByStoreDate: 'filters/getFilteredApprovedByStoreDate',
    }),
    /**
     * Ordenamiento global (filtro en store)
     */
    filteredSortBy: {
      get() { return this.getFilteredSortBy },
    },
    /**
     * Estatus de orden global (filtro en store)
     */
    filteredStatus: {
      get() { return this.getFilteredStatus },
    },
    /**
     * Creador global (filtro en store)
     */
    filteredCreator: {
      get() { return this.getFilteredCreator },
    },
    /**
     * Ubicación global (filtro en store)
     */
    filteredLocation: {
      get() { return this.getFilteredLocation },
    },
    /**
     * Cliente global (filtro en store)
     */
    filteredCustomer: {
      get() { return this.getFilteredCustomer },
    },
    /**
     * Fecha de salida global (filtro en store)
     */
    filteredOutputDate: {
      get() { return this.getFilteredOutputDate },
    },
    /**
     * Búsqueda global (filtro en store)
     */
    filteredSearchTerm: {
      get() { return this.getFilteredSearchTerm },
    },
    /**
     * Fecha requerida global (filtro en store)
     */
    filteredRequiredDate: {
      get() { return this.getFilteredRequiredDate },
    },
    /**
     * Estatus de pago global (filtro en store)
     */
    filteredPaymentStatus: {
      get() { return this.getFilteredPaymentStatus },
    },
    /**
     * Opción de factura global (filtro en store)
     */
    filteredInvoiceOption: {
      get() { return this.getFilteredInvoiceOption },
    },
    /**
     * Mes recibida global (filtro en store)
     */
    filteredReceivedMonth: {
      get() { return this.getFilteredReceivedMonth },
    },
    /**
     * Estatus de comisión global (filtro en store)
     */
    filteredCommissionStatus: {
      get() { return this.getFilteredCommissionStatus },
    },
    /**
     * Fecha aprobada por bodega global (filtro en store)
     */
    filteredApprovedByStoreDate: {
      get() { return this.getFilteredApprovedByStoreDate },
    },
    canShowCustomersFilter() {
      return ((this.userData.Role === 'Administrador' && !this.userDetail)
            || this.typeUser === 'mayorista'
            || this.typeUser === 'vendedor'
            || this.userData.Role === 'Encargado de Facturacion')
            || this.userData.Role === 'Vendedor'
            || this.userData.Role === 'Mayorista'
    },
    canShowCreatorsFilter() {
      return ((this.userData.Role === 'Administrador' && !this.userDetail)
            || this.typeUser === 'cliente')
            || this.userData.Role === 'Coordinador de Almacen'
            || this.userData.Role === 'Encargado de Facturacion'
    },
    canShowPaymentStatusFilter() {
      const roles = ['Administrador', 'Encargado de Facturacion', 'Vendedor', 'Mayorista']
      return roles.includes(this.userData.Role)
    },
    canShowRequiredInvoiceFilter() {
      return this.userData.Role === 'Encargado de Facturacion'
    },
    canShowLocationFilter() {
      const roles = ['Administrador', 'Encargado de Facturacion', 'Vendedor', 'Mayorista']
      return roles.includes(this.userData.Role)
    },
    columnsFilter() {
      if (this.userData.Role === 'Encargado de Facturacion') {
        return ''
      }

      return 2
    },
    /**
     * Columnas de la pestala seleccionada, valida si se deben mostrar las columna de proveedor
     */
    filteredColumns: {
      get() {
        const { selectedColumns } = this.getListsParams.find(p => p.title === this.title) || {}
        return selectedColumns
      },
    },
    /**
     * Columnas filtradas por término de búsqueda del filtro de columnas
     */
    filteredColumsBySearchTerm: {
      get() {
        return this.filteredColumns?.filter(c => c.text.toLowerCase().includes(this.columnsSearchTerm.toLowerCase()))
      },
    },
    canShowColumnsListFilter() {
      return this.$route.name === 'orders-home'
    },
  },
  watch: {
    filterRoute(val) {
      let status = null
      status = this.getOrdersStatus.find(item => item.status === val)
      if (status) {
        this.selectedOrderStatus = status
        return
      }
      // eslint-disable-next-line no-unused-expressions
      status ?? (status = this.getPaymentsStatus.find(item => item.status === val))
      this.selectedPaymentStatus = status
    },
  },

  /**
  * Carga información del estado
  *
  * @summary Si existe información relacionada con los filtros cargados en el store,
  * se cargan en el componente
  */
  mounted() {
    if (this.canApplyGlobalFilters) {
      // Carga el término de búsqueda (filtro en store)
      if (this.filteredSearchTerm) {
        this.$refs['basic-searcher'].loadSearchTerm(this.filteredSearchTerm)
      }

      // Carga la fecha de creación (filtro en store)
      this.selectedDate = this.filteredRequiredDate || ''

      // Carga el ordenamiento (filtro en store)
      this.selectedIdOption = this.filteredSortBy ? { text: this.filteredSortBy } : ''

      // Carga el creador (filtro en store)
      this.selectedCreator = this.filteredCreator ? { item: this.filteredCreator } : ''

      // Carga la ubicación (filtro en store)
      this.selectedLocation = this.filteredLocation ? { text: this.filteredLocation } : ''

      // Carga el cliente (filtro en store)
      this.selectedCustomer = this.filteredCustomer ? { item: this.filteredCustomer } : ''

      // Carga la fecha de salida (filtro en store)
      this.selectedOutputDate = this.filteredOutputDate || ''

      // Carga el estatus de la orden (filtro en store)
      this.selectedOrderStatus = this.filteredStatus || ''

      // Carga el estatus de pago (filtro en store)
      this.selectedPaymentStatus = this.filteredPaymentStatus || ''

      // Carga la opción de factura (filtro en store)
      this.selectedInvoicedOption = this.filteredInvoiceOption ? { text: this.filteredInvoiceOption } : ''

      // Carga el mes recibida (filtro en store)
      this.selectedReceivedMonth = this.filteredReceivedMonth ? this.$moment(this.filteredReceivedMonth) : ''

      // Carga el estatus de comisión (filtro en store)
      this.selectedCommissionStatus = this.filteredCommissionStatus ? { text: this.filteredCommissionStatus } : ''

      // Carga la fecha aprobada por bodega (filtro en store)
      this.selectedApprovedByStoreDate = this.filteredApprovedByStoreDate || ''
    }

    this.$watch('selectedDate', date => {
      this.$emit('on:filter', { type: 'date', value: date })
    })

    this.$watch('selectedIdOption', idOption => {
      this.$emit('on:filter', { type: 'sort', value: idOption !== null ? idOption.text : '' })
    })

    this.$watch('selectedCreator', creator => {
      this.$emit('on:filter', { type: 'creator', value: creator !== '' ? creator.item : '' })
    })

    this.$watch('selectedLocation', location => {
      this.$emit('on:filter', { type: 'location', value: location !== null ? location.text : '' })
    })

    this.$watch('selectedCustomer', customer => {
      this.$emit('on:filter', { type: 'customer', value: customer !== '' ? customer.item : '' })
    })

    this.$watch('selectedOutputDate', date => {
      this.$emit('on:filter', { type: 'output-date', value: date })
    })

    this.$watch('selectedOrderStatus', status => {
      this.$emit('on:filter', { type: 'order-status', value: status !== '' ? status : '' })
    })

    this.$watch('selectedPaymentStatus', status => {
      this.$emit('on:filter', { type: 'payment-status', value: status !== '' ? status : '' })
    })

    this.$watch('selectedInvoicedOption', option => {
      this.$emit('on:filter', { type: 'invoiced', value: option !== null ? option.text : '' })
    })

    this.$watch('selectedReceivedMonth', month => {
      this.$emit('on:filter', { type: 'received-month', value: month !== '' ? month.format('YYYY-MM-DD') : '' })
    })

    this.$watch('selectedCommissionStatus', status => {
      this.$emit('on:filter', { type: 'commission-status', value: status !== null ? status.text : '' })
    })

    this.$watch('selectedApprovedByStoreDate', date => {
      this.$emit('on:filter', { type: 'approved-by-store-date', value: date })
    })
  },

  methods: {
    ...mapActions({
      sendOrdersCSVFile: 'orders/sendOrdersCSVFile',
    }),
    handleSearch(searchTerm) {
      this.selectedDate = ''
      this.selectedCreator = ''
      this.selectedLocation = ''
      this.selectedIdOption = ''
      this.selectedCustomer = ''
      this.selectedOutputDate = ''
      this.selectedOrderStatus = ''
      this.selectedPaymentStatus = ''
      this.selectedReceivedMonth = ''
      this.selectedInvoicedOption = ''
      this.selectedCommissionStatus = ''
      this.selectedApprovedByStoreDate = ''

      this.$emit('on:filter', { type: 'search', value: searchTerm })
    },

    /**
     * Evento de buscador de clientes
     * @summary Atiende el evento de búsqueda del filtro de clientes
     * @param {String} text - Término de búsqueda
     */
    onCustomerNameChange(text) {
      if (text === '' || text === undefined) {
        this.customersOptions = []
        this.$emit('on:filter', { type: 'customer', value: '' })
        return
      }

      const filteredData = this.getClientsSuggests
        .filter(item => `${item.name} ${item.LastName}`.toLowerCase().indexOf(text.toLowerCase()) > -1)
        .slice(0, this.limit)

      const namedSuggests = []

      filteredData.forEach(item => {
        namedSuggests.push({ name: `${item.name} ${item.LastName || ''}`, id: item.id })
      })
      this.customersOptions = [{
        data: namedSuggests,
      }]
    },

    /**
     * Evento de selección de cliente
     * @summary Establece el cliente seleccionado
     */
    onSelectedCustomerName(item) {
      this.selectedCustomer = item
    },

    /**
     * Evento del buscador de creadores
     * @summary Atiende el evento de búsqueda del filtro de creadores de orden
     * @param {String} text - Término de búsqueda
     */
    onCreatorNameChange(text) {
      if (text === '' || text === undefined) {
        this.creatorsOptions = []
        this.$emit('on:filter', { type: 'creator', value: '' })
        return
      }

      const filteredData = this.getCreatorsSuggests
        .filter(item => `${item.name} ${item.LastName}`.toLowerCase().indexOf(text.toLowerCase()) > -1)
        .slice(0, this.limit)

      const namedSuggests = []

      filteredData.forEach(item => {
        namedSuggests.push({ name: `${item.name} ${item.LastName || ''}`, id: item.id })
      })
      this.creatorsOptions = [{
        data: namedSuggests,
      }]
    },

    /**
    * Selección de columnas
    * @summary Emite un evento con la columna seleccionada y su estado (activa o inactiva)
    * @param {Object} column - Columna seleccionada
    */
    onColumnOptionChange(column) {
      const baseColumn = column
      baseColumn.active = !baseColumn.active
      this.$emit('on:column-option-change', baseColumn)

      if (baseColumn.active) {
        return
      }

      // Si se desactiva la columna de fecha de solicitud, se limpia la fecha seleccionada
      if (baseColumn.value === DATE_FILTER_VALUE && this.selectedDate) {
        this.selectedDate = ''
      }

      // Si se desactiva la columna de fecha de salida, se limpia la fecha seleccionada
      if (baseColumn.value === OUTPUT_DATE_FILTER_VALUE && this.selectedOutputDate) {
        this.selectedOutputDate = ''
      }

      // Si se desactiva la columna de fecha aprobada por bodega, se limpia la fecha seleccionada
      if (baseColumn.value === APPROVED_BY_STORE_DATE_FILTER_VALUE && this.selectedApprovedByStoreDate) {
        this.selectedApprovedByStoreDate = ''
      }

      // Si se desactiva la columna de mes completada, se limpia el mes seleccionado
      if (baseColumn.value === COMPLETED_MONTH_FILTER_VALUE && this.selectedReceivedMonth) {
        this.selectedReceivedMonth = ''
      }

      // Si se desactiva la columna de estatus de orden, se limpia el estatus seleccionado
      if (baseColumn.value === STATUS_ORDER_FILTER_VALUE && this.selectedOrderStatus) {
        this.selectedOrderStatus = ''
      }

      // Si se desactiva la columna de estatus de pago, se limpia el estatus seleccionado
      if (baseColumn.value === STATUS_PAYMENT_FILTER_VALUE && this.selectedPaymentStatus) {
        this.selectedPaymentStatus = ''
      }

      // Si se desactiva la columna de factura, se limpia la opción seleccionada
      if (baseColumn.value === REQUIRED_INVOICE_FILTER_VALUE && this.selectedInvoicedOption) {
        this.selectedInvoicedOption = ''
      }

      // Si se desactiva la columna de creador, se limpia el creador seleccionado
      if (baseColumn.value === CREATOR_FILTER_VALUE && this.selectedCreator) {
        this.selectedCreator = ''
      }

      // Si se desactiva la columna de cliente, se limpia el cliente seleccionado
      if (baseColumn.value === CUSTOMER_FILTER_VALUE && this.selectedCustomer) {
        this.selectedCustomer = ''
      }

      // Si se desactiva la columna de ubicación, se limpia la ubicación seleccionada
      if (baseColumn.value === LOCATION_FILTER_VALUE && this.selectedLocation) {
        this.selectedLocation = ''
      }

      // Si se desactiva la columna de ID, se limpia la opción seleccionada
      if (baseColumn.value === ID_FILTER_VALUE && this.selectedIdOption) {
        this.selectedIdOption = ''
      }

      // Si se desactiva la columna de estatus de comisión, se limpia la opción seleccionada
      if (baseColumn.value === COMMISSION_STATUS_FILTER_VALUE && this.selectedCommissionStatus) {
        this.selectedCommissionStatus = ''
      }
    },

    /**
     * Evento de selección de creador
     * @summary Establece el creador seleccionado
     */
    onSelectedCreatorName(item) {
      this.selectedCreator = item
    },

    /**
    * Evento del buscador de filtro de columnas
    * @summary Atiende el evento de búsqueda del filtro de columnas
    * @param {String} searchTerm - Término de búsqueda
    */
    handleSearchColumns(searchTerm) {
      this.columnsSearchTerm = searchTerm
    },

    /**
     * Indica si se debe mostrar el filtro de columnas
     *
     * @param {String} value - Valor de la columna
     */
    canShowFilter(value) {
      const checkedColumns = this.filteredColumns?.filter(c => c.active && c.value === value) || 0
      return checkedColumns.length > 0
    },

    /**
     * Envía un correo electrónico con el reporte de comisiones
     */
    async onSendingEmail() {
      try {
        this.isSendingEmail = true
        await this.sendOrdersCSVFile(this.loadParams())
        this.showToast(
          'Correo enviado',
          'El correo electrónico ha sido enviado correctamente.',
          'success',
        )
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.isSendingEmail = false
      }
    },

    /**
    * Parámetros de carga
    * @summary Devuelve los parámetros de carga de la lista
    */
    loadParams() {
      const params = {
        currentPage: 1,
        sortTerm: this.filteredSortBy,
        userTerm: this.filteredCreator?.id,
        locationTerm: this.filteredLocation,
        searchTerm: this.filteredSearchTerm,
        dateTerm: this.filteredRequiredDate,
        clientTerm: this.filteredCustomer?.id,
        statusOderTerm: this.filteredStatus?.id,
        outputDateTerm: this.filteredOutputDate,
        receivedMonthTerm: this.filteredReceivedMonth,
        invoicedOptionTerm: this.filteredInvoiceOption,
        statusPaymentTerm: this.filteredPaymentStatus?.id,
        commissionStatusTerm: this.filteredCommissionStatus,
        approvedByStoreDateTerm: this.filteredApprovedByStoreDate,
        /**
        * Columns list
        */
        activedColumnsTerm: this.filteredColumns.filter(c => c.active),
      }

      return params
    },

    /**
    * Muestra un mensaje de tipo toast
    * @param {string} title - Título del mensaje
    * @param {string} text - Contenido del mensaje
    * @param {string} variant - Tipo del mensaje (warning, success, danger)
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-autosuggest.scss';
.style-chooser {
  background: #ffffff;
}
.btn-outline-secondary {
  border: 1px solid !important;
  border-color: #c9c9c9 !important;
}
.dropdown-menu {
  min-width: 16rem !important;
}
.vs__selected button {
  display: none
}
</style>
