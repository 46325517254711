var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form-row',[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.title))])])],1),_c('b-form-row',[_c('b-col',{attrs:{"md":"2"}},[_c('BasicSearcher',{ref:"basic-searcher",attrs:{"callback":_vm.handleSearch,"placeholder":_vm.searcherPlaceholder}})],1),_c('b-col',{attrs:{"lg":"2","md":"3"}},[_c('b-form-datepicker',{attrs:{"date-format-options":{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' },"placeholder":"Fecha de creación","direction":"ltr","local":"es","reset-button":""},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1),_c('b-col',{attrs:{"md":"2"}},[_c('v-select',{staticClass:"style-chooser",attrs:{"label":"text","placeholder":"Estatus","options":_vm.getQuotesStatus},model:{value:(_vm.selectedQuoteStatus),callback:function ($$v) {_vm.selectedQuoteStatus=$$v},expression:"selectedQuoteStatus"}})],1),_c('b-col',{attrs:{"md":"2"}},[_c('v-select',{staticClass:"style-chooser",attrs:{"label":"text","placeholder":"ID","options":_vm.getIdOptions},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var text = ref.text;
var icon = ref.icon;
return [_c('feather-icon',{staticClass:"align-middle mr-50",attrs:{"icon":icon,"size":"16"}}),_c('span',[_vm._v(" "+_vm._s(text))])]}}]),model:{value:(_vm.selectedIdOption),callback:function ($$v) {_vm.selectedIdOption=$$v},expression:"selectedIdOption"}})],1),(!_vm.userDetail || _vm.typeUser === 'mayorista' || _vm.typeUser === 'vendedor')?_c('b-col',{attrs:{"md":"2"}},[_c('vue-autosuggest',{attrs:{"suggestions":_vm.customersOptions,"limit":5,"input-props":{
          id:'autosuggest__input1',
          class:'form-control',
          placeholder:'Buscar por cliente'
        }},on:{"input":_vm.onCustomerNameChange,"selected":_vm.onSelectedCustomerName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var suggestion = ref.suggestion;
return [_c('span',{staticClass:"my-suggestion-item"},[_vm._v(_vm._s(suggestion.item.name))])]}}],null,false,3761623635),model:{value:(_vm.filteredCustomer.name),callback:function ($$v) {_vm.$set(_vm.filteredCustomer, "name", $$v)},expression:"filteredCustomer.name"}})],1):_vm._e(),(!_vm.$ability.can('create', 'Quote') && _vm.typeUser === 'cliente')?_c('b-col',{attrs:{"md":"2"}},[_c('vue-autosuggest',{attrs:{"suggestions":_vm.creatorsOptions,"limit":5,"input-props":{
          id:'autosuggest__input2',
          class:'form-control',
          placeholder:'Buscar por creador'
        }},on:{"input":_vm.onCreatorNameChange,"selected":_vm.onSelectedCreatorName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var suggestion = ref.suggestion;
return [_c('span',{staticClass:"my-suggestion-item"},[_vm._v(_vm._s(suggestion.item.name))])]}}],null,false,3761623635),model:{value:(_vm.filteredCreator.name),callback:function ($$v) {_vm.$set(_vm.filteredCreator, "name", $$v)},expression:"filteredCreator.name"}})],1):_vm._e(),(_vm.$ability.can('create', 'Quote'))?_c('b-col',{staticClass:"ml-auto",attrs:{"cols":"12","md":"auto"}},[_c('b-button',{staticClass:"my-1 my-lg-0 w-100 principal-btn",attrs:{"variant":"principal-btn"},on:{"click":function($event){return _vm.$emit('on:click')}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.newItemButtonLabel))])],1)],1):_vm._e(),(_vm.showDownloadFile)?_c('b-col',{attrs:{"cols":"12","md":"auto"}},[_c('b-button',{staticClass:"w-100 csv-btn",attrs:{"variant":"flat-success"}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"DownloadIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Descargar CSV")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }