<!-- eslint-disable import/extensions -->
<template>
  <div>
    <!-- #region begin::Searcher and options for filtering -->
    <b-form-row>

      <!-- #region begin::Searcher and filters -->
      <b-col>
        <b-form-row align-h="start">

          <!-- #endregion begin::Brand filter -->

          <!-- #region::Date filter -->
          <b-col>

            <flat-pickr
              v-model="rangeDate"
              class="form-control"
              :config="config"
              placeholder="Rango de fecha"
              name="date"
              @on-close="onCloseCalendar"
            />

            <b-button
              v-if="showClearFilterButton"
              variant="primary"
              class="mt-1"
              style="padding: 0.5rem 1rem;"
              @click="onClearFilter"
            >
              Limpiar
            </b-button>
          </b-col>
          <!-- #endregion::Date filter -->

        </b-form-row>
      </b-col>
      <!-- #endregion begin::Searcher and filters -->

    </b-form-row>
    <!-- #endregion end::Searcher and options for filtering -->

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BCol, BFormRow, BButton,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line no-unused-vars, import/no-extraneous-dependencies, import/extensions
import { Spanish } from 'flatpickr/dist/l10n/es.js'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/themes/light.css'

export default {
  components: {
    BCol,
    BFormRow,
    BButton,
    flatPickr,
  },
  props: {
    filterType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      rangeDate: '',
      showClearFilterButton: false,
      config: {
        mode: 'range',
        locale: Spanish,
      },

    }
  },
  computed: {
    ...mapGetters({
      getTotalSaleRangeDates: 'dashboard/getTotalSaleRangeDates',
      getRegisteredPaymentsRangeDates: 'dashboard/getRegisteredPaymentsRangeDates',
    }),
    /**
     * Rango de fechas del total de venta
     */
    totalSaleRangeDates: {
      get() { return this.getTotalSaleRangeDates },
      set(value) { this.setTotalSaleRangeDates(value) },
    },
    /**
     * Rango de fechas de los pagos registrados
     */
    registeredPaymentsRangeDates: {
      get() { return this.getRegisteredPaymentsRangeDates },
      set(value) { this.setRegisteredPaymentsRangeDates(value) },
    },
  },
  methods: {
    ...mapActions({
      setTotalSaleRangeDates: 'dashboard/setTotalSaleRangeDates',
      setRegisteredPaymentsRangeDates: 'dashboard/setRegisteredPaymentsRangeDates',
    }),

    /**
    * Limpieza del campo de rangos de fecha
    * @summary Atiende al evento de limpieza del campo de rangos de fecha
    */
    onClearFilter() {
      this.rangeDate = ''

      if (this.filterType === 'totalSale') {
        this.totalSaleRangeDates = ''
      } else if (this.filterType === 'registeredPayments') {
        this.registeredPaymentsRangeDates = ''
      }

      this.showClearFilterButton = false
    },

    /**
    * Evento de selección de fecha
    * @summary Atiendo al evento de selección de fecha del componente flatpickr
    * @param {String} date - Cadena de texto con las fechas seleccionadas
    */
    onCloseCalendar(_, date) {
      const from = date?.split('a')[0]?.trim()
      const to = date?.split('a')[1]?.trim()

      let dateRange = null

      if (from && to) {
        dateRange = { from, to }
      } else if (from && !to) {
        dateRange = { from, to: from }
      }

      if (dateRange) {
        if (this.filterType === 'totalSale') {
          this.totalSaleRangeDates = dateRange
        } else if (this.filterType === 'registeredPayments') {
          this.registeredPaymentsRangeDates = dateRange
        }

        this.showClearFilterButton = true

        const closeCalendar = document?.querySelector('.flatpickr-calendar')
        closeCalendar.classList.remove('open')
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
[class*="collapse-"] {
  .card {
    .card-header {
      cursor: auto;
      padding: 1rem 2.8rem 1rem 1rem;
    }
  }
}
.style-chooser {
  background: #ffffff;
}

.col-padding {
  padding: 0 0.5rem 0 0.5rem;
}
</style>
