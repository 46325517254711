<template>
  <b-card
    v-if="data"
    class="border border-dark shadow-none"
    style="min-height: 10.5rem;"
  >
    <div class="d-flex">
      <h4 class="text-nowrap">
        {{ data.title }}
      </h4>
      <slot
        style="margin: 10px"
        name="datePicker"
      />
    </div>
    <h1 class="mb-75 mt-2 pt-50 text-success">
      {{ data.amount | currency }}
    </h1>
    <b-img
      v-if="data.image"
      :src="require('@/assets/images/illustration/badge.svg')"
      class="congratulation-medal"
      alt="Medal Pic"
    />
  </b-card>
</template>

<script>
import {
  BCard, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BImg,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
