<template>
  <div>

    <!-- #region::Searcher, filters and actions buttons -->
    <OrdersListFilters
      v-show="!isLoadingFiltersData"
      title="Órdenes"
      searcher-placeholder="Buscar orden"
      :user-detail="userDetail"
      :type-user="typeUser"
      :filter-route="filterRoute"
      :can-apply-global-filters="canApplyGlobalFilters"
      @on:filter="handleFilter"
      @on:column-option-change="handleColumnOptionChange"
    />
    <!-- #endregion::Searcher, filters and actions buttons -->

    <!-- #region::Skeleton -->
    <template v-if="isLoadingOrders || isLoadingFiltersData">
      <BasicSkeleton height="200px" />
      <BasicSkeleton height="430px" />
    </template>
    <!-- #endregion::Skeleton -->

    <template v-else>

      <b-card
        class="mt-1 border border-dark shadow-none"
        no-body
      >
        <b-card-text>

          <!-- #region::Quotes list table -->
          <b-table
            v-if="availableOrders"
            :items="orders"
            :fields="columns"
            :tbody-tr-class="canHighLightRow"
            responsive
            class="my-0"
          >

            <!-- #region:Virtual column for index -->
            <template #cell(no)="data">
              {{ indexFrom + data.index }}
            </template>
            <!-- #endregion:Virtual column for index -->

            <!-- #region:Virtual column for ID -->
            <template #cell(ID)="{ item }">
              <router-link :to="{ name: 'order-details', params: { id: item.ID } }">
                {{ item.ID }}
              </router-link>
            </template>
            <!-- #endregion:Virtual column for ID -->

            <!-- #region:Virtual column for customer -->
            <template #cell(client)="{ item }">
              <span class="text-nowrap">{{ item.client }}</span>
            </template>
            <!-- #endregion:Virtual column for customer -->

            <!-- #region::Virtual column for creator user -->
            <template #cell(createdby)="{ item }">
              <template v-if="item.created_by">
                <div class="text-nowrap">
                  {{ item.created_by }}
                </div>
              </template>
              <span
                v-else
                class="text-nowrap"
              >Sin especificar</span>
            </template>
            <!-- #endregion::Virtual column for creator user -->

            <!-- #region::Virtual column for products count -->
            <template #cell(products)="{ item }">
              {{ item.products | placeholder('Sin especificar') }}
            </template>
            <!-- #endregion::Virtual column for products count -->

            <!-- #region::Virtual column for discount -->
            <template #cell(discount)="{ item }">
              {{ item.discount + '%' }}
            </template>
            <!-- #endregion::Virtual column for discount -->

            <!-- #region::Virtual column for subtotal usd -->
            <template #cell(subtotal_usd)="{ item }">
              <span v-if=" item.subtotal_usd">
                {{ item.subtotal_usd | currency }}
              </span>
              <span v-else>Sin especificar</span>
            </template>
            <!-- #endregion::Virtual column for subtotal usd -->

            <!-- #region::Virtual column for exchange rate -->
            <template #cell(exchange)="{ item }">
              <span v-if=" item.exchange">
                {{ item.exchange | currency }}
              </span>
              <span v-else>Sin especificar</span>
            </template>
            <!-- #endregion::Virtual column for exchange rate -->

            <!-- #region::Virtual column for subtotal MXN -->
            <template #cell(subtotal_mxn)="{ item }">
              <span
                v-if=" item.subtotal_mxn"
                class="font-weight-bolder"
              >
                {{ item.subtotal_mxn | currency }}
              </span>
              <span v-else>Sin especificar</span>
            </template>
            <!-- #endregion::Virtual column for subtotal MXN -->

            <!-- #region::Virtual column for IVA -->
            <template #cell(iva)="{ item }">
              <span>
                {{ (item.iva || 0) | currency }}
              </span>
            </template>
            <!-- #endregion::Virtual column for IVA -->

            <!-- #region::Virtual column for discount amount -->
            <template #cell(discount_amount)="{ item }">
              <span>
                {{ (item.discount_amount || 0) | currency }}
              </span>
            </template>
            <!-- #endregion::Virtual column for discount amount -->

            <!-- #region::Virtual column for shipping and installation cost -->
            <template #cell(shipping_and_installation_cost)="{ item }">
              <span>
                {{ (item.shipping_and_installation_cost || 0) | currency }}
              </span>
            </template>
            <!-- #endregion::Virtual column for shipping and installation cost -->

            <!-- #region::Virtual column for subtotal mxn -->
            <template #cell(total_mxn)="{ item }">
              <span v-if=" item.total_mxn">
                {{ item.total_mxn | currency }}
              </span>
              <span v-else>Sin especificar</span>
            </template>
            <!-- #endregion::Virtual column for subtotal mxn -->

            <!-- #region::Virtual column for paid mxn -->
            <template #cell(covered)="{ item }">
              <span
                v-if=" item.covered"
                class="text-nowrap"
              >
                {{ item.covered + '%' }}
              </span>
              <span
                v-else
                class="text-nowrap"
              >Sin especificar</span>
            </template>
            <!-- #endregion::Virtual column for paid mxn -->

            <!-- #region::Virtual column for status payment -->
            <template #cell(payment_status)="{ item }">
              <template v-if="item.payment_status">
                <div
                  :class="item.payment_status === 'Pago liquidado' ? 'alert-green' : 'alert-yellow'"
                  role="alert"
                  class="sm-banner-alert text-nowrap"
                  style="display: inline; font-size: 11px;"
                >
                  {{ item.payment_status }}
                </div>
              </template>
              <span
                v-else
                class="text-nowrap"
              >Sin especificar</span>
            </template>
            <!-- #endregion::Virtual column for status payment -->

            <!-- #region::Virtual column for invoice -->
            <template #cell(invoice)="{ item }">
              <span class="invoiced">
                {{ item.invoice }} <feather-icon
                  v-if="item.Invoiced"
                  size="16"
                  class="text-success ml-25"
                  icon="CheckIcon"
                />
              </span>
            </template>
            <!-- #endregion::Virtual column for invoice -->

            <!-- #region::Virtual column for invoice and output date list -->
            <template #cell(invoice_and_exit_date)="{ item }">
              <OrderInvoiceDateCell
                v-if="canShowInvoiceAndExitDate(item.invoice_and_exit_date)"
                :invoices="item.invoice_and_exit_date"
                :local-search-term="localSearchTerm || ''"
                :local-output-date="localOutputDate || ''"
                :can-apply-global-filters="canApplyGlobalFilters"
              />
              <span
                v-else
                class="text-nowrap"
              >Sin entrega</span>
            </template>
            <!-- #endregion::Virtual column for invoice and output date list -->

            <!-- #region::Virtual column for required date -->
            <template #cell(fecha_requerida)="{ item }">
              {{ item.fecha_requerida | moment("DD/MM/YYYY") }}
            </template>
            <!-- #endregion::Virtual column for required date -->

            <!-- #region::Virtual column for status order -->
            <template #cell(order_status)="{ item }">
              <template v-if="item.order_status">
                <div
                  :class="[statusBorderStyle(item.order_status), statusColorStyle(item.order_status)]"
                  role="alert"
                  class="text-nowrap"
                  style="display: inline; font-size: 11px;"
                >
                  {{ item.order_status }}
                </div>
              </template>
              <span
                v-else
                class="text-nowrap"
              >Sin especificar</span>
            </template>
            <!-- #endregion::Virtual column for status order -->

            <!-- #region::Virtual column for creator user -->
            <template #cell(created_by)="{ item }">
              <template v-if="item.created_by">
                <div class="text-nowrap">
                  {{ item.created_by }}
                </div>
              </template>
              <span
                v-else
                class="text-nowrap"
              >Sin especificar</span>
            </template>
            <!-- #endregion::Virtual column for creator user -->

            <!-- #region::Virtual column for location user -->
            <template #cell(location)="{ item }">
              <template v-if="item.location">
                <div
                  :class="[statusBorderStyle(item.location), statusColorStyle(item.location)]"
                  role="alert"
                  class="text-nowrap"
                  style="display: inline; font-size: 11px;"
                >
                  {{ item.location }}
                </div>
              </template>
              <span
                v-else
                class="text-nowrap"
              >Sin especificar</span>
            </template>
            <!-- #endregion::Virtual column for location user -->

            <!-- #region::Virtual column for approved date -->
            <template #cell(fecha_aprobada)="{ item }">
              <span class="text-nowrap">{{ item.fecha_aprobada | moment("DD/MM/YYYY hh:mm a") }}</span>
            </template>
            <!-- #endregion::Virtual column for approved date -->

            <!-- #region::Virtual column for confirmed delivery date -->
            <template #cell(confirmed_delivery_date)="{ item }">
              <span
                v-if="item.confirmed_delivery_date"
                class="text-nowrap"
              >{{ item.confirmed_delivery_date | moment("DD/MM/YYYY") }}</span>
              <span
                v-else
                class="text-nowrap"
              >Sin fecha</span>
            </template>
            <!-- #endregion::Virtual column for confirmed delivery date -->

            <!-- #region::Virtual column for commission -->
            <template #cell(commission)="{ item }">
              <template v-if="COMMISSIONS_STATUS.includes(item.commission)">
                <div
                  :class="['sm-banner-alert', statusColorStyle(item.commission)]"
                  role="alert"
                  class="text-nowrap ml-0"
                  style="display: inline; font-size: 11px;"
                >
                  {{ item.commission }}
                </div>
              </template>
              <span
                v-else
                class="text-nowrap"
              >Por definir</span>
            </template>
            <!-- #endregion::Virtual column for commission -->

            <!-- #region::Virtual column for completed month date -->
            <template #cell(completed_date)="{ item }">
              <span
                v-if="item.completed_date"
                class="text-nowrap"
              >{{ item.completed_date | moment("MMMM YYYY") | capitalize }}</span>
              <span
                v-else
                class="text-nowrap"
              >Sin especificar</span>
            </template>
            <!-- #endregion::Virtual column for completed month date -->

          </b-table>
          <!-- #endregion::Quotes list table -->

          <!-- #region::Alert when no circuits are available -->
          <b-alert
            v-if="!availableOrders && !isLoadingOrders && !isLoadingFiltersData"
            variant="warning"
            class="my-0"
            show
          >
            <div class="alert-body">
              <span>No se encontraron órdenes.</span>
            </div>
          </b-alert>
          <!-- #endregion::Alert when no circuits are available -->

        </b-card-text>
      </b-card>
    </template>

    <!-- #region begin::Pagination & items per list -->
    <BasicPaginator
      v-if="availableOrders"
      ref="basic-paginator"
      class="mt-2"
      :total-rows="totalOrders"
      :callback="handleChangePagination"
      :can-apply-global-pagination="canApplyGlobalPagination"
    />
    <!-- #endregion end::Pagination & items per list -->

  </div>
</template>

<script>
// #region Imports
import { mapGetters, mapActions } from 'vuex'
import {
  BCard, BCardText, BTable, BAlert,
} from 'bootstrap-vue'

import getError from '@/helpers/ErrorsHandler'
import BasicSkeleton from '@/components/cards/BasicSkeleton.vue'
import BasicPaginator from '@/components/tables/BasicPaginator.vue'
import OrdersListFilters from '@/modules/trade/orders/components/OrdersListFilters.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import OrderInvoiceDateCell from '@/modules/trade/orders/components/lists/OrderInvoiceDateCell.vue'
// #endregion

const COMMISSIONS_STATUS = ['Pendiente', 'Por pagar', 'Pagado']
const LIST_TITLE = 'Órdenes'

export default {
  components: {
    BCard,
    BAlert,
    BTable,
    BCardText,
    BasicSkeleton,
    BasicPaginator,
    OrdersListFilters,
    OrderInvoiceDateCell,
  },
  props: {
    /**
     * Contiene los detalles del usuario cliente
     */
    userDetail: {
      type: Boolean,
      default: false,
    },
    /**
     * Tipo de usuario asociado a la cotización
     */
    typeUser: {
      type: String,
      default: '',
    },
    /**
     * Representa un identificador externo de usuario
     */
    idUserExternal: {
      type: String,
      default: null,
    },
    /**
     * Representa un identificador externo de cliente
     */
    idClientExternal: {
      type: String,
      default: null,
    },
    /**
     * Indica si se pueden aplicar los filtros globales
     */
    canApplyGlobalFilters: {
      type: Boolean,
      default: true,
    },
    /**
     * Indica si se puede aplicar la paginación global
     */
    canApplyGlobalPagination: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      userData: '',
      columns: [
        {
          key: 'no',
          label: 'No.',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'location',
          label: 'Ubicación',
          thStyle: 'text-transform: none;',
          thClass: (!this.canWarehouseCoordinator())
            ? 'bg-light'
            : 'd-none',
          tdClass: (!this.canWarehouseCoordinator())
            ? ''
            : 'd-none',
        },
        {
          key: 'ID',
          label: 'ID',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'quote_id',
          label: 'ID Cotización',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        /**
         * Se muestra en la lista de ordenes
         */
        {
          key: 'created_by',
          label: 'Creado por',
          thStyle: 'text-transform: none;',
          thClass: ((this.canLoadAdminFacturation() && !this.userDetail)
                  || (this.canWarehouseCoordinator() && !this.userDetail))
            ? 'bg-light'
            : 'd-none',
          tdClass: ((this.canLoadAdminFacturation() && !this.userDetail)
                  || (this.canWarehouseCoordinator() && !this.userDetail))
            ? ''
            : 'd-none',
        },
        /**
         * Se muestra en la lista de detalles
         */
        {
          key: 'CreatedBy',
          label: 'Creado por',
          thStyle: 'text-transform: none;',
          thClass: (((this.canLoadAdminFacturation() && this.userDetail) && (this.typeUser === 'cliente'))
                  || (this.canWarehouseCoordinator() && this.userDetail) || (this.canLoadWholesaleSeller() && this.userDetail))
            ? 'bg-light text-nowrap'
            : 'd-none',
          tdClass: (((this.canLoadAdminFacturation() && this.userDetail) && (this.typeUser === 'cliente'))
                  || (this.canWarehouseCoordinator() && this.userDetail) || (this.canLoadWholesaleSeller() && this.userDetail))
            ? ''
            : 'd-none',
        },
        {
          key: 'covered',
          label: 'Pagado',
          thStyle: 'text-transform: none;',
          thClass: (!this.canWarehouseCoordinator())
            ? 'bg-light'
            : 'd-none',
          tdClass: (!this.canWarehouseCoordinator())
            ? ''
            : 'd-none',
        },
        {
          key: 'payment_status',
          label: 'Estatus de Pago',
          thStyle: 'text-transform: none;',
          thClass: (!this.canWarehouseCoordinator())
            ? 'bg-light'
            : 'd-none',
          tdClass: (!this.canWarehouseCoordinator())
            ? ''
            : 'd-none',
        },
        {
          key: 'order_status',
          label: 'Estatus de Orden',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'commission',
          label: 'Comisión',
          thStyle: 'text-transform: none;',
          thClass: (!this.canWarehouseCoordinator())
            ? 'bg-light'
            : 'd-none',
          tdClass: (!this.canWarehouseCoordinator())
            ? ''
            : 'd-none',
        },
        {
          key: 'completed_date',
          label: 'Mes completada',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'invoice_and_exit_date',
          label: 'Folio y fecha de salida',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'invoice',
          label: 'Solicitud factura',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'tax_situation',
          label: 'Constancia',
          thStyle: 'text-transform: none;',
          thClass: (!this.canWarehouseCoordinator())
            ? 'bg-light'
            : 'd-none',
          tdClass: (!this.canWarehouseCoordinator())
            ? ''
            : 'd-none',
        },
        {
          key: 'products',
          label: 'Productos',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'subtotal_usd',
          label: 'Subtotal USD',
          thStyle: 'text-transform: none;',
          thClass: (!this.canWarehouseCoordinator())
            ? 'bg-light'
            : 'd-none',
          tdClass: (!this.canWarehouseCoordinator())
            ? ''
            : 'd-none',
        },
        {
          key: 'exchange',
          label: 'T.C.',
          thStyle: 'text-transform: none;',
          thClass: (!this.canWarehouseCoordinator())
            ? 'bg-light'
            : 'd-none',
          tdClass: (!this.canWarehouseCoordinator())
            ? ''
            : 'd-none',
        },
        {
          key: 'subtotal_mxn',
          label: 'Subtotal MXN',
          thStyle: 'text-transform: none;',
          thClass: (!this.canWarehouseCoordinator())
            ? 'bg-light'
            : 'd-none',
          tdClass: (!this.canWarehouseCoordinator())
            ? ''
            : 'd-none',
        },
        {
          key: 'discount',
          label: 'Descuento',
          thStyle: 'text-transform: none;',
          thClass: (!this.canWarehouseCoordinator())
            ? 'bg-light'
            : 'd-none',
          tdClass: (!this.canWarehouseCoordinator())
            ? ''
            : 'd-none',
        },
        {
          key: 'discount_amount',
          label: 'Monto de descuento',
          thStyle: 'text-transform: none;',
          thClass: (!this.canWarehouseCoordinator())
            ? 'bg-light'
            : 'd-none',
          tdClass: (!this.canWarehouseCoordinator())
            ? ''
            : 'd-none',
        },
        {
          key: 'iva',
          label: 'IVA',
          thStyle: 'text-transform: none;',
          thClass: (!this.canWarehouseCoordinator())
            ? 'bg-light'
            : 'd-none',
          tdClass: (!this.canWarehouseCoordinator())
            ? ''
            : 'd-none',
        },
        {
          key: 'shipping_and_installation_cost',
          label: 'Envío e instalación',
          thStyle: 'text-transform: none;',
          thClass: (!this.canWarehouseCoordinator())
            ? 'bg-light'
            : 'd-none',
          tdClass: (!this.canWarehouseCoordinator())
            ? ''
            : 'd-none',
        },
        {
          key: 'total_mxn',
          label: 'Total MXN',
          thStyle: 'text-transform: none;',
          thClass: (!this.canWarehouseCoordinator())
            ? 'bg-light'
            : 'd-none',
          tdClass: (!this.canWarehouseCoordinator())
            ? ''
            : 'd-none',
        },
        {
          key: 'client',
          label: 'Cliente',
          thStyle: 'text-transform: none;',
          thClass: (((this.canLoadAdminFacturation() && !this.userDetail) || (this.typeUser === 'mayorista') || this.typeUser === 'vendedor')
                  || (this.canLoadWholesaleSeller() && !this.userDetail))
            ? 'bg-light'
            : 'd-none',
          tdClass: (((this.canLoadAdminFacturation() && !this.userDetail) || (this.typeUser === 'mayorista') || this.typeUser === 'vendedor')
                  || (this.canLoadWholesaleSeller() && !this.userDetail))
            ? ''
            : 'd-none',
        },
        {
          key: 'fecha_aprobada',
          label: 'Fecha aprobada',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'fecha_requerida',
          label: 'Fecha requerida',
          thStyle: 'text-transform: none;',
          thClass: (this.canLoadAdminFacturation()
                  || this.canWarehouseCoordinator() || this.canLoadWholesaleSeller())
            ? 'bg-light'
            : 'd-none',
          tdClass: (this.canLoadAdminFacturation()
                  || this.canWarehouseCoordinator() || this.canLoadWholesaleSeller())
            ? ''
            : 'd-none',
        },
        {
          key: 'confirmed_delivery_date',
          label: 'Aprobada por bodega',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
      ],
      orders: [],
      isLoadingOrders: false,
      isLoadingFiltersData: false,

      indexFrom: 0,
      totalOrders: 0,
      filterRoute: '',
      routeParmas: '',

      /**
       * Local filters
       */
      localSortBy: null,
      localStatus: null,
      localCreator: null,
      localLocation: null,
      localCustomer: null,
      localOutputDate: null,
      localSearchTerm: null,
      localRequiredDate: null,
      localPaymentStatus: null,
      localInvoiceOption: null,
      localReceivedMonth: null,
      localCommissionStatus: null,
      localApprovedByStoreDate: null,

      /**
       * Local pagination
       */
      lastPage: 0,
      localPerPage: 10,
      localCurrentPage: 1,

      COMMISSIONS_STATUS,
    }
  },
  computed: {
    ...mapGetters({
      getColumnsList: 'orders/getColumnsList',
      /**
       * Filtros globales
       */
      getFilteredSortBy: 'filters/getFilteredSortBy',
      getFilteredStatus: 'filters/getFilteredStatus',
      getListsParams: 'filters/getFilteredListParams',
      getFilteredCreator: 'filters/getFilteredCreator',
      getFilteredPerPage: 'filters/getFilteredPerPage',
      getFilteredLocation: 'filters/getFilteredLocation',
      getFilteredCustomer: 'filters/getFilteredCustomer',
      getFilteredOutputDate: 'filters/getFilteredOutputDate',
      getFilteredSearchTerm: 'filters/getFilteredSearchTerm',
      getFilteredCurrentPage: 'filters/getFilteredCurrentPage',
      getFilteredRequiredDate: 'filters/getFilteredRequiredDate',
      getFilteredPaymentStatus: 'filters/getFilteredPaymentStatus',
      getFilteredInvoiceOption: 'filters/getFilteredInvoiceOption',
      getFilteredReceivedMonth: 'filters/getFilteredReceivedMonth',
      getFilteredCommissionStatus: 'filters/getFilteredCommissionStatus',
      getFilteredApprovedByStoreDate: 'filters/getFilteredApprovedByStoreDate',
    }),
    /**
     * Listas de parametros de filtrado de pestañas
     */
    listsParams: {
      get() { return this.getListsParams },
    },
    /**
     * Lista de columnas
     */
    columnsList: {
      get() { return this.getColumnsList },
    },
    /**
     * Ordenamiento global (filtro en store)
     */
    filteredSortBy: {
      get() { return this.getFilteredSortBy },
      set(value) { this.setFilteredSortBy(value) },
    },
    /**
     * Estatus de orden global (filtro en store)
     */
    filteredStatus: {
      get() { return this.getFilteredStatus },
      set(value) { this.setFilteredStatus(value) },
    },
    /**
     * Creador global (filtro en store)
     */
    filteredCreator: {
      get() { return this.getFilteredCreator },
      set(value) { this.setFilteredCreator(value) },
    },
    /**
     * Cliente global (filtro en store)
     */
    filteredCustomer: {
      get() { return this.getFilteredCustomer },
      set(value) { this.setFilteredCustomer(value) },
    },
    /**
     * Fecha de salida global (filtro en store)
     */
    filteredOutputDate: {
      get() { return this.getFilteredOutputDate },
      set(value) { this.setFilteredOutputDate(value) },
    },
    /**
     * Indica la cantidad de elementos por página (paginado global)
     */
    filteredPerPage: {
      get() { return this.getFilteredPerPage },
      set(value) { this.setFilteredPerPage(value) },
    },
    /**
     * Ubicación global (filtro en store)
     */
    filteredLocation: {
      get() { return this.getFilteredLocation },
      set(value) { this.setFilteredLocation(value) },
    },
    /**
     * Término de búsqueda filtrado (filtro en store)
     */
    filteredSearchTerm: {
      get() { return this.getFilteredSearchTerm },
      set(value) { this.setFilteredSearchTerm(value) },
    },
    /**
     * Indica la página actual (paginado global)
     */
    filteredCurrentPage: {
      get() { return this.getFilteredCurrentPage },
      set(value) { this.setFilteredCurrentPage(value) },
    },
    /**
     * Fecha requerida global (filtro en store)
     */
    filteredRequiredDate: {
      get() { return this.getFilteredRequiredDate },
      set(value) { this.setFilteredRequiredDate(value) },
    },
    /**
     * Estatus de pago global (filtro en store)
     */
    filteredPaymentStatus: {
      get() { return this.getFilteredPaymentStatus },
      set(value) { this.setFilteredPaymentStatus(value) },
    },
    /**
     * Opción de factura global (filtro en store)
     */
    filteredInvoiceOption: {
      get() { return this.getFilteredInvoiceOption },
      set(value) { this.setFilteredInvoiceOption(value) },
    },
    /**
     * Filtro de mes de recepción (filtro en store)
     */
    filteredReceivedMonth: {
      get() { return this.getFilteredReceivedMonth },
      set(value) { this.setFilteredReceivedMonth(value) },
    },
    /**
     * Filtro de estatus de comisión (filtro en store)
     */
    filteredCommissionStatus: {
      get() { return this.getFilteredCommissionStatus },
      set(value) { this.setFilteredCommissionStatus(value) },
    },
    /**
     * Fecha aprobada por bodega global (filtro en store)
     */
    filteredApprovedByStoreDate: {
      get() { return this.getFilteredApprovedByStoreDate },
      set(value) { this.setFilteredApprovedByStoreDate(value) },
    },
    availableOrders() {
      return this.totalOrders > 0
    },
    isLoadedParamsForList() {
      return this.listsParams.findIndex(params => params.title === LIST_TITLE) !== -1
    },
  },

  /**
  * Hook que se ejecuta cuando el componente es montado
  * @summary Verifica si hay parámetros en la ruta para cargar el filtro
  * proporcionados por el componente de dashboard. De lo contrario
  * carga la lista de órdenes y los filtros
  * @exception ⁡⁢⁣⁢Error de validación en la carga de registros de órdenes⁡
  */
  async mounted() {
    try {
      this.userData = JSON.parse(localStorage.userData)
      if (this.routeParmas) {
        this.filterRoute = this.routeParmas
        this.listenerParmasDashboard('')
      } else {
        this.isLoadingFiltersData = true
        await this.loadColumnsList()
        await this.loadFiltersData()
        this.isLoadingFiltersData = false
        await this.loadOrdersListWithVerification()

        if (this.isLoadedParamsForList) {
          const { selectedColumns } = this.listsParams.find(p => p.title === LIST_TITLE)
          const hiddenColumns = selectedColumns.filter(c => c.active === false)

          hiddenColumns.forEach(hiddenColumn => {
            this.handleColumnOptionChange(hiddenColumn)
          })
        }
      }
    } catch (error) {
      this.$swal.close()
      this.showToast('Error de validación', getError(error), 'danger')
    }
  },

  /**
  * Hook que se ejecuta cuando se crea el componente
  *
  * @summary Establece el filtro cargado en la ruta originario del dashboard
  * y carga los datos del usuario
  */
  async created() {
    this.routeParmas = this.$store.state.dashboard.paramsDashboard
    this.userData = JSON.parse(localStorage.userData)
  },
  methods: {
    ...mapActions({
      loadOrders: 'orders/loadOrders',
      loadColumnsList: 'orders/loadColumnsList',
      loadOrdersStatus: 'orders/loadOrdersStatus',
      loadPaymentsStatus: 'orders/loadPaymentsStatus',
      loadClientsSuggests: 'orders/loadClientsSuggests',
      loadCreatorsSuggests: 'orders/loadCreatorsSuggests',
      listenerParmasDashboard: 'dashboard/listenerParmasDashboard',

      /**
       * Filtros globales
       */
      saveListParams: 'filters/saveListParams',
      setFilteredSortBy: 'filters/setFilteredSortBy',
      setFilteredStatus: 'filters/setFilteredStatus',
      setFilteredCreator: 'filters/setFilteredCreator',
      setFilteredPerPage: 'filters/setFilteredPerPage',
      setFilteredCustomer: 'filters/setFilteredCustomer',
      setFilteredLocation: 'filters/setFilteredLocation',
      setFilteredOutputDate: 'filters/setFilteredOutputDate',
      setFilteredSearchTerm: 'filters/setFilteredSearchTerm',
      setFilteredCurrentPage: 'filters/setFilteredCurrentPage',
      setFilteredRequiredDate: 'filters/setFilteredRequiredDate',
      setFilteredPaymentStatus: 'filters/setFilteredPaymentStatus',
      setFilteredInvoiceOption: 'filters/setFilteredInvoiceOption',
      setFilteredReceivedMonth: 'filters/setFilteredReceivedMonth',
      setFilteredCommissionStatus: 'filters/setFilteredCommissionStatus',
      setFilteredApprovedByStoreDate: 'filters/setFilteredApprovedByStoreDate',
    }),

    /**
    * Evento de filtrado
    *
    * @summary Atiende el evento de filtrado del compontente filter, asignando
    * términos de búsqueda en base a los campos de selección y el buscador así como
    * establecer los filtros globales en el store
    * @param {Object} filter - Objeto con el tipo de filtro y el valor
    */
    async handleFilter(filter) {
      switch (filter.type) {
        case 'date':
          if (this.canApplyGlobalFilters) {
            this.filteredRequiredDate = filter.value
          } else {
            this.localRequiredDate = filter.value
          }
          break
        case 'order-status':
          if (this.canApplyGlobalFilters) {
            this.filteredStatus = filter.value
          } else {
            this.localStatus = filter.value
          }
          break
        case 'payment-status':
          if (this.canApplyGlobalFilters) {
            this.filteredPaymentStatus = filter.value
          } else {
            this.localPaymentStatus = filter.value
          }
          break
        case 'invoiced':
          if (this.canApplyGlobalFilters) {
            this.filteredInvoiceOption = filter.value
          } else {
            this.localInvoiceOption = filter.value
          }
          break
        case 'sort':
          if (this.canApplyGlobalFilters) {
            this.filteredSortBy = filter.value
          } else {
            this.localSortBy = filter.value
          }
          break
        case 'customer':
          if (this.canApplyGlobalFilters) {
            this.filteredCustomer = filter.value
          } else {
            this.localCustomer = filter.value
          }
          break
        case 'output-date':
          if (this.canApplyGlobalFilters) {
            this.filteredOutputDate = filter.value
          } else {
            this.localOutputDate = filter.value
          }
          break
        case 'creator':
          if (this.canApplyGlobalFilters) {
            this.filteredCreator = filter.value
          } else {
            this.localCreator = filter.value
          }
          break
        case 'location':
          if (this.canApplyGlobalFilters) {
            this.filteredLocation = filter.value
          } else {
            this.localLocation = filter.value
          }
          break
        case 'approved-by-store-date':
          if (this.canApplyGlobalFilters) {
            this.filteredApprovedByStoreDate = filter.value
          } else {
            this.localApprovedByStoreDate = filter.value
          }
          break
        case 'received-month': {
          if (this.canApplyGlobalFilters) {
            this.filteredReceivedMonth = filter.value
          } else {
            this.localReceivedMonth = filter.value
          }
          break
        }
        case 'commission-status':
          if (this.canApplyGlobalFilters) {
            this.filteredCommissionStatus = filter.value
          } else {
            this.localCommissionStatus = filter.value
          }
          break
        default:
          if (this.canApplyGlobalFilters) {
            this.filteredSearchTerm = filter.value
            this.filteredCurrentPage = 1
          } else {
            this.localSearchTerm = filter.value
            this.localCurrentPage = 1
          }
          // eslint-disable-next-line no-unused-expressions
          this.$refs['basic-paginator']?.resetCurrentPage()
          break
      }

      this.loadOrdersListWithVerification()
    },

    /**
    * Carga de elementos de la lista con verificación de paginado
    *
    * @summary Carga la lista de elementos y verifica que la página actual contenga elementos
    */
    async loadOrdersListWithVerification() {
      await this.loadOrdersList()
      await this.verifyContenList()
    },

    /**
    * Evento de paginación
    *
    * @summary Evento del componente de paginación. Establece la página actual y la cantidad
    * de registros por página
    * @param {number} currentPage - Página actual
    * @param {number} perPage - Elementos a mostrar por página
    */
    async handleChangePagination(currentPage, perPage) {
      if (this.canApplyGlobalPagination) {
        this.filteredPerPage = perPage
        this.filteredCurrentPage = currentPage
      } else {
        this.localPerPage = perPage
        this.localCurrentPage = currentPage
      }
      this.loadOrdersList()
    },

    /**
    * Verificación de contenido
    *
    * @summary Verifica que la página actual tenga registros, en caso de no tenerlos
    * tomará la última página disponible y cargará los registros
    */
    async verifyContenList() {
      if (this.orders.length === 0) {
        if (this.canApplyGlobalPagination) {
          this.filteredCurrentPage = this.lastPage
        } else {
          this.localCurrentPage = this.lastPage
        }

        // Verifica si hay registros disponibles en el servidor
        if (this.availableOrders) {
          this.$refs['basic-paginator'].resetCurrentPage(this.lastPage)
          await this.loadOrdersList()
        }
      }
    },

    /**
    * Carga de registros de tipo órdenes
    *
    * @summary ⁡⁢⁣⁡⁢⁢⁡⁣⁣⁡⁣⁢⁣⁡⁣⁢⁢⁡⁢⁢⁢‍Carga y establece los registros de órdenes y los parámetros de paginación⁡⁡⁡⁡⁡⁡
    * @exception ⁡⁢⁣⁢Error de validación en la carga de registros de órdenes⁡
    */
    async loadOrdersList() {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()
        this.isLoadingOrders = true

        const response = await this.loadOrders(this.loadParams())

        this.orders = response.data.data.data
        this.totalOrders = response.data.data.total
        this.indexFrom = response.data.data.from
        this.lastPage = response.data.data.last_page

        this.isLoadingOrders = false
        this.$swal.close()
      } catch (error) {
        this.$swal.close()
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.$swal.close()
      }
    },

    /**
    * Información de filtros
    *
    * @summary Carga la información de los filtros de órdenes
    * @exception ⁡⁢⁣⁢Error de validación en la carga de registros de filtros de órdenes⁡
    */
    async loadFiltersData() {
      try {
        await this.loadOrdersStatus()
        await this.loadPaymentsStatus()

        if (this.canLoadCustomers()) {
          await this.loadClientsSuggests('')
        }

        if (this.canLoadCreators()) {
          await this.loadCreatorsSuggests('')
        }
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      }
    },

    /**
    * Parámetros de carga
    *
    * @summary Devuelve los parámetros de carga de la lista
    */
    loadParams() {
      const params = {
        title: LIST_TITLE,
        sortTerm: this.canApplyGlobalFilters ? this.filteredSortBy : this.localSortBy,
        perPage: this.canApplyGlobalPagination ? this.filteredPerPage : this.localPerPage,
        locationTerm: this.canApplyGlobalFilters ? this.filteredLocation : this.localLocation,
        searchTerm: this.canApplyGlobalFilters ? this.filteredSearchTerm : this.localSearchTerm,
        dateTerm: this.canApplyGlobalFilters ? this.filteredRequiredDate : this.localRequiredDate,
        statusOderTerm: this.canApplyGlobalFilters ? this.filteredStatus?.id : this.localStatus?.id,
        outputDateTerm: this.canApplyGlobalFilters ? this.filteredOutputDate : this.localOutputDate,
        currentPage: this.canApplyGlobalPagination ? this.filteredCurrentPage : this.localCurrentPage,
        receivedMonthTerm: this.canApplyGlobalFilters ? this.filteredReceivedMonth : this.localReceivedMonth,
        invoicedOptionTerm: this.canApplyGlobalFilters ? this.filteredInvoiceOption : this.localInvoiceOption,
        statusPaymentTerm: this.canApplyGlobalFilters ? this.filteredPaymentStatus?.id : this.localPaymentStatus?.id,
        commissionStatusTerm: this.canApplyGlobalFilters ? this.filteredCommissionStatus : this.localCommissionStatus,
        userTerm: this.idUserExternal ?? (this.canApplyGlobalFilters ? this.filteredCreator?.id : this.localCreator?.id),
        clientTerm: this.idClientExternal ?? (this.canApplyGlobalFilters ? this.filteredCustomer?.id : this.localCustomer?.id),
        approvedByStoreDateTerm: this.canApplyGlobalFilters ? this.filteredApprovedByStoreDate : this.localApprovedByStoreDate,

        selectedColumns: this.isLoadedParamsForList
          ? this.getListsParams.find(p => p.title === LIST_TITLE).selectedColumns
          : this.columnsList.map(a => ({ ...a })),
      }

      this.saveListParams(params)

      return params
    },

    /**
     * Determina si se pueden cargar los clientes para los filtros
     */
    canLoadCustomers() {
      const roles = ['Administrador', 'Vendedor', 'Encargado de Facturacion', 'Mayorista']
      return roles.includes(this.userData?.Role)
    },

    /**
     * Determina si se pueden cargar los creadores para los filtros
     */
    canLoadCreators() {
      const roles = ['Administrador', 'Coordinador de Almacen', 'Encargado de Facturacion']
      return roles.includes(this.userData?.Role)
    },
    preventHighlightRow() {
      const role = JSON.parse(localStorage.userData)?.Role
      return role === 'Coordinador de Almacen'
    },
    canLoadWholesaleSeller() {
      const role = JSON.parse(localStorage.userData)?.Role
      return role === 'Vendedor'
          || role === 'Mayorista'
    },
    canLoadAdminFacturation() {
      const role = JSON.parse(localStorage.userData)?.Role
      return role === 'Administrador'
          || role === 'Encargado de Facturacion'
    },
    canWarehouseCoordinator() {
      const role = JSON.parse(localStorage.userData)?.Role
      return role === 'Coordinador de Almacen'
    },

    /**
    * Establece el color del borde del banner para el estado de la orden
    * @param {string} status - Estado de la orden
    */
    statusBorderStyle(status) {
      switch (status) {
        case 'Completada':
          return 'sm-banner-alert-bordered'
        case 'Traspasada':
          return 'sm-banner-alert-bordered'
        default:
          return 'sm-banner-alert'
      }
    },

    /**
    * Establece el color de banner para el estado de la orden
    * @param {string} status - Estado de la orden
    */
    statusColorStyle(status) {
      switch (status) {
        case 'Aprobada':
        case 'Bodega CDMX':
        case 'Pagado':
          return 'alert-green'
        case 'Lista para entrega':
        case 'Tienda':
        case 'Por pagar':
          return 'alert-yellow'
        case 'Traspaso en proceso':
          return 'alert-yellow'
        case 'Entrega completa':
        case 'Bodega GDL':
          return 'alert-blue'
        case 'Entrega parcial':
        case 'Pendiente':
          return 'alert-cyan'
        case 'En bodega':
          return 'alert-purple'
        case 'Detenida':
          return 'alert-gray'
        case 'Cancelada':
          return 'alert-gray'
        case 'Completada':
          return 'alert-green-white'
        case 'En suministro':
          return 'alert-orange'
        case 'Traspaso':
          return 'alert-marine'
        case 'Traspaso aprobado':
          return 'alert-green'
        case 'Traspasada':
          return 'alert-green-white'
        default:
          return 'alert-blue'
      }
    },

    /**
    * Determina el color del registro en base a su ubicación
    */
    canHighLightRow(order) {
      if (this.preventHighlightRow()) {
        return ''
      }

      switch (order.location) {
        case 'Bodega GDL':
          return 'blue-row'
        case 'Tienda':
          return 'yellow-row'
        default:
          return ''
      }
    },

    /**
     * Determina si puede mostrar la lista de facturas y fechas de salida
     */
    canShowInvoiceAndExitDate(invoicesList) {
      return invoicesList && invoicesList?.length > 0
    },

    /**
    * Gestionador de cambio de opción de columna
    * @summary Gestiona el cambio de opción de columna. Oculta o muestra la columna
    * @param {Object} column - Objeto de la columna
    */
    handleColumnOptionChange(column) {
      const listColumn = this.columns.find(c => c.key === column.value)
      this.$set(listColumn, 'thClass', column.active ? 'bg-light' : 'd-none')
      this.$set(listColumn, 'tdClass', column.active ? '' : 'd-none')
    },

    /**
    * Muestra un mensaje de tipo toast
    * @param {string} title - Título del mensaje
    * @param {string} text - Contenido del mensaje
    * @param {string} variant - Tipo del mensaje (warning, success, danger)
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
<style lang="scss">
.blue-row {
  background-color: #F7FCFF;
}

.yellow-row {
  background-color: #FFFEF2;
}
</style>
