<template>
  <div>
    <b-form-row>

      <!-- #region::Title -->
      <b-col class="align-self-center">
        <h3>{{ title }}</h3>
      </b-col>
      <!-- #endregion::Title -->

      <!-- #region::Save button -->
      <b-col
        v-if="$ability.can('edit', `${user}`) && showEditButton"
        cols="12"
        md="auto"
        class="ml-auto"
      >
        <b-button
          variant="principal-btn"
          class="my-1 my-md-0 w-100 principal-btn"
          @click="$emit('on:edit')"
        >
          <feather-icon
            icon="Edit3Icon"
            class="mr-50"
          />
          <span class="align-middle">{{ editButtonText }}</span>
        </b-button>
      </b-col>
      <!-- #endregion::Save button -->

      <!-- #region::Delete button -->
      <b-col
        v-if="$ability.can('delete', `${user}`) && showDeleteButton"
        cols="12"
        md="auto"
      >
        <b-button
          variant="delete-btn"
          class="my-lg-0 w-100 delete-btn"
          @click="$emit('on:delete')"
        >
          <feather-icon
            icon="Trash2Icon"
            class="mr-50"
          />
          <span class="align-middle">{{ deleteButtonText }}</span>
        </b-button>
      </b-col>
      <!-- #endregion::Delete button -->

    </b-form-row>
  </div>
</template>

<script>
import {
  BCol, BButton, BFormRow,
} from 'bootstrap-vue'

export default {
  name: 'MainOptions',
  components: {
    BCol,
    BButton,
    BFormRow,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    editButtonText: {
      type: String,
      required: true,
    },
    deleteButtonText: {
      type: String,
      default: 'Eliminar',
    },
    user: {
      type: String,
      default: '',
    },
    showEditButton: {
      type: Boolean,
      default: true,
    },
    showDeleteButton: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
