var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('OrdersListFilters',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoadingFiltersData),expression:"!isLoadingFiltersData"}],attrs:{"title":"Órdenes","searcher-placeholder":"Buscar orden","user-detail":_vm.userDetail,"type-user":_vm.typeUser,"filter-route":_vm.filterRoute,"can-apply-global-filters":_vm.canApplyGlobalFilters},on:{"on:filter":_vm.handleFilter,"on:column-option-change":_vm.handleColumnOptionChange}}),(_vm.isLoadingOrders || _vm.isLoadingFiltersData)?[_c('BasicSkeleton',{attrs:{"height":"200px"}}),_c('BasicSkeleton',{attrs:{"height":"430px"}})]:[_c('b-card',{staticClass:"mt-1 border border-dark shadow-none",attrs:{"no-body":""}},[_c('b-card-text',[(_vm.availableOrders)?_c('b-table',{staticClass:"my-0",attrs:{"items":_vm.orders,"fields":_vm.columns,"tbody-tr-class":_vm.canHighLightRow,"responsive":""},scopedSlots:_vm._u([{key:"cell(no)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.indexFrom + data.index)+" ")]}},{key:"cell(ID)",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'order-details', params: { id: item.ID } }}},[_vm._v(" "+_vm._s(item.ID)+" ")])]}},{key:"cell(client)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.client))])]}},{key:"cell(createdby)",fn:function(ref){
var item = ref.item;
return [(item.created_by)?[_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(item.created_by)+" ")])]:_c('span',{staticClass:"text-nowrap"},[_vm._v("Sin especificar")])]}},{key:"cell(products)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("placeholder")(item.products,'Sin especificar'))+" ")]}},{key:"cell(discount)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.discount + '%')+" ")]}},{key:"cell(subtotal_usd)",fn:function(ref){
var item = ref.item;
return [( item.subtotal_usd)?_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.subtotal_usd))+" ")]):_c('span',[_vm._v("Sin especificar")])]}},{key:"cell(exchange)",fn:function(ref){
var item = ref.item;
return [( item.exchange)?_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.exchange))+" ")]):_c('span',[_vm._v("Sin especificar")])]}},{key:"cell(subtotal_mxn)",fn:function(ref){
var item = ref.item;
return [( item.subtotal_mxn)?_c('span',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm._f("currency")(item.subtotal_mxn))+" ")]):_c('span',[_vm._v("Sin especificar")])]}},{key:"cell(iva)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")((item.iva || 0)))+" ")])]}},{key:"cell(discount_amount)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")((item.discount_amount || 0)))+" ")])]}},{key:"cell(shipping_and_installation_cost)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")((item.shipping_and_installation_cost || 0)))+" ")])]}},{key:"cell(total_mxn)",fn:function(ref){
var item = ref.item;
return [( item.total_mxn)?_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.total_mxn))+" ")]):_c('span',[_vm._v("Sin especificar")])]}},{key:"cell(covered)",fn:function(ref){
var item = ref.item;
return [( item.covered)?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(item.covered + '%')+" ")]):_c('span',{staticClass:"text-nowrap"},[_vm._v("Sin especificar")])]}},{key:"cell(payment_status)",fn:function(ref){
var item = ref.item;
return [(item.payment_status)?[_c('div',{staticClass:"sm-banner-alert text-nowrap",class:item.payment_status === 'Pago liquidado' ? 'alert-green' : 'alert-yellow',staticStyle:{"display":"inline","font-size":"11px"},attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(item.payment_status)+" ")])]:_c('span',{staticClass:"text-nowrap"},[_vm._v("Sin especificar")])]}},{key:"cell(invoice)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"invoiced"},[_vm._v(" "+_vm._s(item.invoice)+" "),(item.Invoiced)?_c('feather-icon',{staticClass:"text-success ml-25",attrs:{"size":"16","icon":"CheckIcon"}}):_vm._e()],1)]}},{key:"cell(invoice_and_exit_date)",fn:function(ref){
var item = ref.item;
return [(_vm.canShowInvoiceAndExitDate(item.invoice_and_exit_date))?_c('OrderInvoiceDateCell',{attrs:{"invoices":item.invoice_and_exit_date,"local-search-term":_vm.localSearchTerm || '',"local-output-date":_vm.localOutputDate || '',"can-apply-global-filters":_vm.canApplyGlobalFilters}}):_c('span',{staticClass:"text-nowrap"},[_vm._v("Sin entrega")])]}},{key:"cell(fecha_requerida)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.fecha_requerida,"DD/MM/YYYY"))+" ")]}},{key:"cell(order_status)",fn:function(ref){
var item = ref.item;
return [(item.order_status)?[_c('div',{staticClass:"text-nowrap",class:[_vm.statusBorderStyle(item.order_status), _vm.statusColorStyle(item.order_status)],staticStyle:{"display":"inline","font-size":"11px"},attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(item.order_status)+" ")])]:_c('span',{staticClass:"text-nowrap"},[_vm._v("Sin especificar")])]}},{key:"cell(created_by)",fn:function(ref){
var item = ref.item;
return [(item.created_by)?[_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(item.created_by)+" ")])]:_c('span',{staticClass:"text-nowrap"},[_vm._v("Sin especificar")])]}},{key:"cell(location)",fn:function(ref){
var item = ref.item;
return [(item.location)?[_c('div',{staticClass:"text-nowrap",class:[_vm.statusBorderStyle(item.location), _vm.statusColorStyle(item.location)],staticStyle:{"display":"inline","font-size":"11px"},attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(item.location)+" ")])]:_c('span',{staticClass:"text-nowrap"},[_vm._v("Sin especificar")])]}},{key:"cell(fecha_aprobada)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("moment")(item.fecha_aprobada,"DD/MM/YYYY hh:mm a")))])]}},{key:"cell(confirmed_delivery_date)",fn:function(ref){
var item = ref.item;
return [(item.confirmed_delivery_date)?_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("moment")(item.confirmed_delivery_date,"DD/MM/YYYY")))]):_c('span',{staticClass:"text-nowrap"},[_vm._v("Sin fecha")])]}},{key:"cell(commission)",fn:function(ref){
var item = ref.item;
return [(_vm.COMMISSIONS_STATUS.includes(item.commission))?[_c('div',{staticClass:"text-nowrap ml-0",class:['sm-banner-alert', _vm.statusColorStyle(item.commission)],staticStyle:{"display":"inline","font-size":"11px"},attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(item.commission)+" ")])]:_c('span',{staticClass:"text-nowrap"},[_vm._v("Por definir")])]}},{key:"cell(completed_date)",fn:function(ref){
var item = ref.item;
return [(item.completed_date)?_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("capitalize")(_vm._f("moment")(item.completed_date,"MMMM YYYY"))))]):_c('span',{staticClass:"text-nowrap"},[_vm._v("Sin especificar")])]}}],null,false,2681419481)}):_vm._e(),(!_vm.availableOrders && !_vm.isLoadingOrders && !_vm.isLoadingFiltersData)?_c('b-alert',{staticClass:"my-0",attrs:{"variant":"warning","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v("No se encontraron órdenes.")])])]):_vm._e()],1)],1)],(_vm.availableOrders)?_c('BasicPaginator',{ref:"basic-paginator",staticClass:"mt-2",attrs:{"total-rows":_vm.totalOrders,"callback":_vm.handleChangePagination,"can-apply-global-pagination":_vm.canApplyGlobalPagination}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }