<template>
  <div>

    <!-- #region begin:title -->
    <b-form-row>
      <b-col
        cols="12"
        class="mb-1"
      >
        <h3>{{ title }}</h3>
      </b-col>
    </b-form-row>
    <!-- #endregion end:title -->

    <!-- #region begin:: List options -->
    <b-form-row>

      <!-- #region begin::Searcher -->
      <b-col md="2">
        <BasicSearcher
          ref="basic-searcher"
          :callback="handleSearch"
          :placeholder="searcherPlaceholder"
        />
      </b-col>
      <!-- #endregion end::Searcher -->

      <!-- #region::Date filter -->
      <b-col
        lg="2"
        md="3"
      >
        <b-form-datepicker
          v-model="selectedDate"
          :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
          placeholder="Fecha de creación"
          direction="ltr"
          local="es"
          reset-button
        />
      </b-col>
      <!-- #endregion::Date filter -->

      <!-- #region::Status select filter -->
      <b-col md="2">
        <v-select
          v-model="selectedQuoteStatus"
          label="text"
          class="style-chooser"
          placeholder="Estatus"
          :options="getQuotesStatus"
        />
      </b-col>
      <!-- #endregion::Status select filter -->

      <!-- #region::ID sorter filter -->
      <b-col md="2">
        <v-select
          v-model="selectedIdOption"
          label="text"
          class="style-chooser"
          placeholder="ID"
          :options="getIdOptions"
        >
          <template #option="{ text, icon }">
            <feather-icon
              :icon="icon"
              size="16"
              class="align-middle mr-50"
            />
            <span> {{ text }}</span>
          </template>
        </v-select>
      </b-col>
      <!-- #endregion::ID sorter filter -->

      <!-- #region::Customer searcher filter -->
      <b-col
        v-if="!userDetail || typeUser === 'mayorista' || typeUser === 'vendedor'"
        md="2"
      >
        <vue-autosuggest
          v-model="filteredCustomer.name"
          :suggestions="customersOptions"
          :limit="5"
          :input-props="{
            id:'autosuggest__input1',
            class:'form-control',
            placeholder:'Buscar por cliente'
          }"
          @input="onCustomerNameChange"
          @selected="onSelectedCustomerName"
        >
          <template slot-scope="{suggestion}">
            <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
          </template>
        </vue-autosuggest>
      </b-col>
      <!-- #endregion::Customer searcher filter -->

      <!-- #region::Customer searcher filter -->
      <b-col
        v-if="!$ability.can('create', 'Quote') && typeUser === 'cliente'"
        md="2"
      >
        <vue-autosuggest
          v-model="filteredCreator.name"
          :suggestions="creatorsOptions"
          :limit="5"
          :input-props="{
            id:'autosuggest__input2',
            class:'form-control',
            placeholder:'Buscar por creador'
          }"
          @input="onCreatorNameChange"
          @selected="onSelectedCreatorName"
        >
          <template slot-scope="{suggestion}">
            <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
          </template>
        </vue-autosuggest>
      </b-col>
      <!-- #endregion::Customer searcher filter -->

      <!-- #region begin::New item button -->
      <b-col
        v-if="$ability.can('create', 'Quote')"
        cols="12"
        md="auto"
        class="ml-auto"
      ><b-button
        variant="principal-btn"
        class="my-1 my-lg-0 w-100 principal-btn"
        @click="$emit('on:click')"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        />
        <span class="align-middle">{{ newItemButtonLabel }}</span>
      </b-button></b-col>
      <!-- #endregion end::New item button -->

      <!-- #region begin::Download CSV button -->
      <b-col
        v-if="showDownloadFile"
        cols="12"
        md="auto"
      ><b-button
        variant="flat-success"
        class="w-100 csv-btn"
      >
        <feather-icon
          icon="DownloadIcon"
          class="mr-50"
        />
        <span class="align-middle">Descargar CSV</span>
      </b-button></b-col>
      <!-- #endregion end::Download CSV button -->

    </b-form-row>
    <!-- #endregion end:: List options -->
  </div>
</template>

<script>
// #region Imports
import { mapGetters } from 'vuex'
import {
  BButton, BCol, BFormRow, BFormDatepicker,
} from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'

import vSelect from 'vue-select'
import BasicSearcher from '@/components/forms/BasicSearcher.vue'
// #endregion

export default {
  components: {
    BCol,
    BButton,
    vSelect,
    BFormRow,
    BasicSearcher,
    VueAutosuggest,
    BFormDatepicker,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    searcherPlaceholder: {
      type: String,
      required: true,
    },
    newItemButtonLabel: {
      type: String,
      required: true,
    },
    showDownloadFile: {
      type: Boolean,
      default: false,
    },
    userDetail: {
      type: Boolean,
      default: false,
    },
    typeUser: {
      type: String,
      default: '',
    },
    filterRoute: {
      type: Object,
      default: () => ({ text: '' }),
    },
    /**
     * Determina si se cargan los filtros globales del estado
     */
    canApplyGlobalFilters: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedDate: '',
      selectedClient: '',
      selectedCreator: '',
      selectedIdOption: '',
      selectedQuoteStatus: '',

      customersOptions: [],
      creatorsOptions: [],
    }
  },
  computed: {
    ...mapGetters({
      getIdOptions: 'quotes/getIdOptions',
      getQuotesStatus: 'quotes/getQuotesStatus',
      getClientsSuggests: 'quotes/getClientsSuggests',
      getCreatorsSuggests: 'quotes/getCreatorsSuggests',

      /**
       * Filtros globales
       */
      getFilteredSortBy: 'filters/getFilteredSortBy',
      getFilteredStatus: 'filters/getFilteredStatus',
      getFilteredCreator: 'filters/getFilteredCreator',
      getFilteredCustomer: 'filters/getFilteredCustomer',
      getFilteredSearchTerm: 'filters/getFilteredSearchTerm',
      getFilteredCreatedAtDate: 'filters/getFilteredCreatedAtDate',
    }),
    /**
     * Ordenamiento global (filtro en store)
     */
    filteredSortBy: {
      get() { return this.getFilteredSortBy },
    },
    /**
     * Filtro de estatus (filtro en store)
     */
    filteredStatus: {
      get() { return this.getFilteredStatus },
    },
    /**
     * Filtro de creador (filtro en store)
     */
    filteredCreator: {
      get() { return this.getFilteredCreator },
    },
    /**
     * Filtro de cliente (filtro en store)
     */
    filteredCustomer: {
      get() { return this.getFilteredCustomer },
    },
    /**
     * Filtro de búsqueda (filtro en store)
     */
    filteredSearchTerm: {
      get() { return this.getFilteredSearchTerm },
    },
    /**
     * Filtro de fecha de creación (filtro en store)
     */
    filteredCreatedAtDate: {
      get() { return this.getFilteredCreatedAtDate },
    },
  },
  watch: {
    selectedCreator(creator) {
      this.$emit('on:filter', { type: 'creator', value: creator !== null ? creator.item : '' })
    },
    filterRoute(val) {
      this.selectedQuoteStatus = val
    },
  },

  /**
  * Hook que se ejecuta cuando el componente se ha montado
  *
  * @summary Si existe información relacionada con los filtros cargados en el store,
  * se cargan en el componente. Se establece un observador para cada filtro
  */
  mounted() {
    // If we can apply global filters, load them from the store into the filter component
    if (this.canApplyGlobalFilters) {
      // Carga el término de búsqueda (filtro en store)
      if (this.filteredSearchTerm) {
        this.$refs['basic-searcher'].loadSearchTerm(this.filteredSearchTerm)
      }

      // Carga la fecha de creación (filtro en store)
      this.selectedDate = this.filteredCreatedAtDate || ''

      // Carga el estatus (filtro en store)
      this.selectedQuoteStatus = this.filteredStatus ? { text: this.filteredStatus } : ''

      // Carga el ordenamiento (filtro en store)
      this.selectedIdOption = this.filteredSortBy ? { text: this.filteredSortBy } : ''

      // Carga el cliente (filtro en store)
      this.selectedClient = this.filteredCustomer ? { item: this.filteredCustomer } : ''

      // Carga el creador (filtro en store)
      this.selectedCreator = this.filteredCreator ? { item: this.filteredCreator } : ''
    }

    this.$watch('selectedDate', date => {
      this.$emit('on:filter', { type: 'date', value: date })
    })

    this.$watch('selectedQuoteStatus', status => {
      this.$emit('on:filter', { type: 'status', value: status !== null ? status.text : '' })
    })

    this.$watch('selectedIdOption', idOption => {
      this.$emit('on:filter', { type: 'sort', value: idOption !== null ? idOption.text : '' })
    })

    this.$watch('selectedClient', client => {
      this.$emit('on:filter', { type: 'client', value: client !== null ? client.item : '' })
    })

    this.$watch('selectedCreator', creator => {
      this.$emit('on:filter', { type: 'creator', value: creator !== null ? creator.item : '' })
    })
  },
  methods: {
    handleSearch(searchTerm) {
      this.$emit('on:filter', { type: 'search', value: searchTerm })

      this.selectedDate = ''
      this.selectedClient = null
      this.selectedCreator = null
      this.selectedIdOption = null
      this.selectedQuoteStatus = null
    },
    onCustomerNameChange(text) {
      if (text === '' || text === undefined) {
        this.customersOptions = []
        this.$emit('on:filter', { type: 'client', value: '' })
        return
      }

      const filteredData = this.getClientsSuggests
        .filter(item => `${item.name} ${item.LastName}`.toLowerCase().indexOf(text.toLowerCase()) > -1)
        .slice(0, this.limit)

      const namedSuggests = []

      filteredData.forEach(item => {
        namedSuggests.push({ name: `${item.name} ${item.LastName}`, id: item.IdUser })
      })

      this.customersOptions = [{
        data: namedSuggests,
      }]
    },
    onSelectedCustomerName(item) {
      this.selectedClient = item
    },
    onCreatorNameChange(text) {
      if (text === '' || text === undefined) {
        this.creatorsOptions = []
        this.$emit('on:filter', { type: 'creator', value: '' })
        return
      }

      const filteredData = this.getCreatorsSuggests
        .filter(item => `${item.name} ${item.LastName}`.toLowerCase().indexOf(text.toLowerCase()) > -1)
        .slice(0, this.limit)

      const namedSuggests = []

      filteredData.forEach(item => {
        namedSuggests.push({ name: `${item.name} ${item.LastName || ''}`, id: item.IdUser })
      })

      this.creatorsOptions = [{
        data: namedSuggests,
      }]
    },
    onSelectedCreatorName(item) {
      this.selectedCreator = item
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-autosuggest.scss';
.style-chooser {
  background: #ffffff;
}
</style>
